import {
  GlobalIntl,
  HamburgerChartLayer,
  HamburgerChartOrientation,
  HamburgerChartPoint,
  TooltipData,
  getHamburgerPointColorFromLayerColor,
} from '@yarmill/components';
import { lowerCaseFirstCharacter } from '@yarmill/utils';
import { computed, makeObservable } from 'mobx';
import { DataDefinitionV2, ReportItem } from '../types';
import {
  fillStringTemplate,
  formatValueByBusinessFormat,
  mapColor,
} from '../utils';
import { BaseReportStore } from './base-report-store';

export class HamburgerReportStore extends BaseReportStore {
  private readonly _pageCode: string;

  constructor(item: ReportItem, pageCode: string) {
    super(item);
    this._pageCode = pageCode;
    makeObservable(this);
  }

  @computed
  public get layers(): HamburgerChartLayer[] {
    return this._item.DataDefinition.filter(l => l.Purpose === 'Category').map(
      l => ({
        name: l.Name,
        color: l.Color ?? '',
        highlightColor: l.HighlightColor ?? l.Color ?? '',
        pointColor: l.PointColor,
      })
    );
  }

  @computed
  public get points(): HamburgerChartPoint[] {
    return this.pointDefinitions.map(p => ({
      name: p.Name,
      fillColor: p.Color ?? null,
      radius: p.Size ?? 6,
      stroke: p.MarkerStrokeWidth ? Number(p.MarkerStrokeWidth) : 0,
      strokeColor: p.MarkerStrokeColor ?? p.Color ?? '',
    }));
  }

  @computed
  public get highlightedLayers(): string[] {
    return this._item.DataDefinition.filter(l => l.Purpose === 'Flag').map(
      f => f.Name
    );
  }

  @computed
  public get orientation(): HamburgerChartOrientation {
    return this._item.Params?.Orientation ?? 'vertical';
  }

  @computed
  public get gap(): number {
    return this._item.Params?.CategoryGap ?? 4;
  }

  @computed
  public get borderColor(): string | undefined {
    return this._item.Params?.BorderColor;
  }

  public getTooltipData(
    value: number,
    data: Record<string, number | string> | undefined
  ): TooltipData {
    const points = this.pointDefinitions.filter(p => data?.[p.Name] === value);

    const values = points.map(def => {
      const tooltipItemLabel = def?.TooltipItemLabel;
      const tooltipItemValue = def?.TooltipItemValue;

      const replacementValues =
        tooltipItemLabel || tooltipItemValue
          ? this.formatItemByDataTypes(data)
          : {};

      const color = def.Color
        ? mapColor(def.Color)
        : (this.getPointColorFromLayerColor(data, value) ?? '');

      return {
        color,
        label: GlobalIntl.formatMessage(
          {
            id: tooltipItemLabel
              ? tooltipItemLabel
              : def?.Label ||
                `reporting.${lowerCaseFirstCharacter(
                  this._pageCode
                )}.${lowerCaseFirstCharacter(
                  this._item.Code
                )}.${lowerCaseFirstCharacter(def?.Name)}`,
          },
          replacementValues
        ),
        value: tooltipItemValue
          ? fillStringTemplate(tooltipItemValue, replacementValues)
          : formatValueByBusinessFormat(
              data?.[def.Name],
              def?.BusinessFormat,
              def?.Format ?? null
            ),
        originalValue: data?.[def.Name],
        hidden: def.HideInTooltip,
      };
    });

    return {
      key: points[0]?.Name ?? '-',
      showLabel: false,
      label: undefined,
      values,
      showEmptyTooltip: Boolean(this._item.Params?.ShowEmptyTooltip),
    };
  }

  private getPointColorFromLayerColor(
    data: Record<string, number | string> | undefined,
    value: number
  ): string {
    return getHamburgerPointColorFromLayerColor(this.layers, data ?? {}, value);
  }

  @computed
  private get pointDefinitions(): DataDefinitionV2[] {
    return this._item.DataDefinition.filter(l => l.Purpose === 'DataValue');
  }

  private formatItemByDataTypes(
    item: Record<string, number | string> | undefined
  ): Record<string, string> {
    if (!item) {
      return {};
    }

    const entries = Object.entries(item).map(([key, value]) => {
      const def = this._item.DataDefinition.find(d => d.Name === key);

      return [
        key,
        def
          ? formatValueByBusinessFormat(value, def.BusinessFormat, def.Format, {
              forceString: true,
            })
          : value,
      ];
    });

    return Object.fromEntries(entries);
  }

  @computed
  public get height(): number | undefined {
    return this._item.Params?.Height;
  }
}
