import { LoadingDots, Tag, TagAppearance } from '@yarmill/component-library';
import { Yollanda } from '@yarmill/icon-library';
import { useFormikContext } from 'formik';
import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import {
  AbstractLoadingSkeleton,
  DocumentFormSection,
  DocumentFormSectionRow,
} from '../components';
import { MetodejDocument } from '../types';
import { TextField } from './text-field';

export const AbstractField = observer(function AbstractField() {
  const intl = useIntl();
  const formik = useFormikContext<MetodejDocument>();
  const { abstract } = formik.values;

  return (
    <DocumentFormSection
      headline={
        <>
          {intl.formatMessage({
            id: 'metodej.document.form.section.abstract.headline',
          })}
          {(abstract === null || abstract?.abstract === null) && (
            <Tag
              text={
                <>
                  {intl.formatMessage({
                    id: 'metodej.library.detail.abstract.headline.generatingBadgeText',
                  })}
                  <LoadingDots appearance="_12M" />
                </>
              }
              appearance={TagAppearance.brand}
              icon={<Yollanda />}
            />
          )}
        </>
      }
    >
      <DocumentFormSectionRow>
        {abstract !== null && abstract?.abstract !== null ? (
          <TextField name="abstract.abstract" asTextarea noLabel />
        ) : (
          <AbstractLoadingSkeleton />
        )}
      </DocumentFormSectionRow>
    </DocumentFormSection>
  );
});
