import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { Icon, IconSize } from './icon';
import { loadingPlaceholderGradientDark } from './loading-placeholder-gradient';
import { Text } from './text';
import { TextAppearance, css, styled } from './theme-provider';

type ItemSize = 'small' | 'large';
interface SideMenuItemProps {
  readonly icon: ReactElement;
  readonly size?: ItemSize;
  readonly selected?: boolean;
  readonly notification?: number;
  readonly href: string;
  readonly text: string;
  readonly showLoader?: boolean;
}

const Layout = styled(Link)<{ readonly $size: ItemSize }>`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  text-decoration: none;

  ${({ theme, $size }) => css`
    gap: ${theme.size.x1};
    padding: ${$size === 'small' ? theme.size.x05 : theme.size.x1} ${theme.size.x1};
    padding-left: ${theme.size.x05};
    border-radius: ${theme.borderRadius.x1};
    color: ${({ theme }) => theme.color.neutral_neutral};

    :hover {
      background-color: ${theme.color.background_background_02};
    }
  `}

  ${({ theme, 'aria-current': selected }) =>
    selected &&
    css`
      color: ${theme.color.neutral_neutral_dark};
      background-color: ${theme.color.background_background_01};
      :hover {
        background-color: ${theme.color.background_background_04};
      }
  `}
`;

const LinkText = styled(Text)`
    justify-self: stretch;
    flex: 1 0 0;
`;

const Notification = styled(Text)`
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: unset;

  ${({ theme }) => css`
      width: ${theme.size.x2};
      height: ${theme.size.x2};
      color: ${theme.color.brand_yarmill_6};
      background-color: ${theme.color.brand_yarmill};
      border-radius: 50%;
  `};
`;

const NotificationLoader = styled.div`
    ${loadingPlaceholderGradientDark};
    border-radius: 50%;
    ${({ theme }) => css`
      width: ${theme.size.x2};
      height: ${theme.size.x2};
  `};
`;

function getTextAppearance(selected: boolean, size: ItemSize): TextAppearance {
  if (size === 'small') {
    return selected ? '_12M' : '_12M';
  } else {
    return selected ? '_16M' : '_16M';
  }
}

export function SideMenuItem({
  href,
  icon,
  selected = false,
  text,
  notification,
  size = 'small',
  showLoader,
}: SideMenuItemProps): ReactElement {
  return (
    <Layout to={href} aria-current={selected ? 'page' : false} $size={size}>
      <Icon size={IconSize.s20}>{icon}</Icon>
      <LinkText appearance={getTextAppearance(selected, size)}>{text}</LinkText>
      {(notification || showLoader) && (
        <>
          {showLoader ? (
            <NotificationLoader />
          ) : (
            <Notification appearance="_10B">{notification}</Notification>
          )}
        </>
      )}
    </Layout>
  );
}
