import { Locale } from '@yarmill/types';
import {
  useCurrentUserStore,
  useIntlStore,
  useSettingsService,
} from '@yarmill/utils';
import { useCallback } from 'react';

export function useChangeLanguageHandler() {
  const intlStore = useIntlStore();
  const currentUser = useCurrentUserStore();
  const settingsService = useSettingsService();

  return useCallback(
    async (lang: Locale): Promise<void> => {
      void intlStore.loadTranslations(lang);

      currentUser.updateSetting('app.language', lang);
      await settingsService.saveSettings(
        'app.language',
        lang,
        {
          userId: currentUser.id,
        },
        false
      );

      window.location.reload();
    },
    [currentUser, intlStore, settingsService]
  );
}
