import { css, styled } from '../theme-provider';

export const DropdownButton = styled.button<{
  readonly $isNested?: boolean;
  readonly $hasFocusInside?: boolean;
}>`
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  border-radius: ${({ theme }) => theme.borderRadius.x075};
  transition-property: border, background-color, color, opacity;
  transition-duration: 150ms;
  user-select: none;
  text-align: left;
  flex-shrink: 1;
  flex-grow: 0;
  justify-content: flex-start;
  border: 0;
  box-shadow: none;
  background-color: transparent;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  outline: none;
  padding: 0;
  max-width: 100%;
  overflow: hidden;
  color: inherit;

  :focus-visible {
    outline: none;
  }

  ${({ $isNested }) =>
    $isNested &&
    css`
      border-radius: ${({ theme }) => theme.borderRadius.x1};
      padding: ${({ theme }) => `${theme.size.x1}`};
      :hover,
      :focus {
        background-color: ${({ theme }) => theme.color.neutral_neutral_darker};
      }
    `}

  ${({ $hasFocusInside, $isNested }) =>
    $hasFocusInside &&
    $isNested &&
    css`
      background-color: ${({ theme }) => theme.color.neutral_neutral_darker};
    `}
`;
