import { FormInputError } from './form-input-error';
import { FormInputHint } from './form-input-hint';
import { FormInputLabel } from './form-input-label';
import { FormInputLayout } from './form-input-layout';
import { SelectBoxInput, SelectBoxInputOption } from './select-box-input';

interface SelectBoxProps<V> {
  readonly options: SelectBoxInputOption<V>[];
  readonly id: string;
  readonly value?: V;
  readonly label: string;
  readonly emptyValueLabel: string;
  readonly searchable?: boolean;
  readonly searchInputPlaceholder?: string;
  readonly hint?: string;
  readonly onChange?: (value: V) => void;
  readonly error?: string;
}

export function SelectBox<V>({
  label,
  id,
  options,
  value,
  emptyValueLabel,
  searchable,
  searchInputPlaceholder,
  hint,
  error,
  onChange,
}: SelectBoxProps<V>) {
  const labelId = `${id}-${label}`;
  return (
    <FormInputLayout>
      <FormInputLabel htmlFor={id} id={labelId}>
        {label}
      </FormInputLabel>
      <SelectBoxInput
        id={id}
        aria-labelledby={labelId}
        options={options}
        value={value}
        emptyValueLabel={emptyValueLabel}
        searchable={searchable}
        searchInputPlaceholder={searchInputPlaceholder}
        onChange={onChange}
      />
      {hint && !error && <FormInputHint>{hint}</FormInputHint>}
      {error && <FormInputError>{error}</FormInputError>}
    </FormInputLayout>
  );
}
