import { Drawer, DrawerLayout, PanelToolbar } from '@yarmill/component-library';
import { observer } from 'mobx-react-lite';
import { ToggleSideMenu } from '../buttons/toggle-side-menu';
import { UploadFilesButton } from '../buttons/upload-files-button';
import { UploadDrawerLoadingSkeleton } from '../components/upload-drawer-loading-skeleton';
import { useDocumentsUploadStore } from '../hooks/use-documents-upload-store';
import { DocumentsToCheckList } from './documents-to-check-list';
import { UploadingDocumentsList } from './uploading-documents-list';

export const UploadDrawer = observer(function UploadDrawer() {
  const uploadStore = useDocumentsUploadStore();

  return (
    <DrawerLayout>
      <Drawer
        header={
          <PanelToolbar
            left={
              <>
                <ToggleSideMenu />
                <UploadFilesButton />
              </>
            }
          />
        }
      >
        {uploadStore.initialDataLoaded ? (
          <>
            <DocumentsToCheckList />
            <UploadingDocumentsList />
          </>
        ) : (
          <UploadDrawerLoadingSkeleton />
        )}
      </Drawer>
    </DrawerLayout>
  );
});
