import {
  ExtendedRefs,
  FloatingContext,
  FloatingFocusManager,
  FloatingList,
  FloatingPortal,
  useTransitionStatus,
  useTransitionStyles,
} from '@floating-ui/react';
import {
  CSSProperties,
  MutableRefObject,
  ReactElement,
  useContext,
  useEffect,
  useRef,
} from 'react';
import { Text } from '../text';
import { styled } from '../theme-provider';
import { DropdownContext } from './dropdown-context';
import { DropdownOption } from './dropdown-provider';
import { Footer } from './footer';
import { Option } from './option';
import { SearchField } from './search-field';

const DropdownContainer = styled.div<{ readonly hasLabel?: boolean }>`
  min-width: 240px;
  max-width: 300px;
  display: flex;
  flex-direction: column;

  outline: none;
  background-color: ${({ theme }) => theme.color.neutral_black};
  border-radius: ${({ theme }) => theme.borderRadius.x15};
  box-shadow: ${({ theme }) => theme.boxShadow.bs2};
  padding: ${({ theme }) => theme.size.x1};
  ${({ hasLabel }) => hasLabel && `padding-top: 0;`};
  z-index: 1;

  & > svg {
    fill: ${({ theme }) => theme.color.neutral_black};
  }
  box-sizing: border-box;
  * {
    box-sizing: border-box;
  }
`;

const DropdownLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.color.neutral_white};
  background-color: ${({ theme }) => theme.color.neutral_black};
  padding: ${({ theme }) => theme.size.x15};
  border-top-left-radius: ${({ theme }) => theme.borderRadius.x15};
  border-top-right-radius: ${({ theme }) => theme.borderRadius.x15};
`;

const OptionsList = styled.div`
  height: 100%;
  overflow: hidden auto;
  padding: ${({ theme }) => theme.size.x025};

  :empty {
    display: none;
  }

  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const OptionsListContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.size.x05};
`;

const DropdownContent = styled.div`
  display: flex;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  border-radius: ${({ theme }) => theme.borderRadius.x15};
`;

const FloatingElement = styled.div`
  z-index: 2;
  overflow: hidden;
  display: flex;
  border-radius: ${({ theme }) => theme.borderRadius.x15};
`;

interface DropdownListProps<V> {
  readonly floatingContext: FloatingContext;
  readonly searchable?: boolean;
  readonly options: DropdownOption<V>[];
  readonly isNested?: boolean;
  readonly floatingRefs: ExtendedRefs<Element>;
  readonly floatingStyles: CSSProperties;
  readonly getFloatingProps: () => Record<string, unknown>;
  readonly label?: string;
  readonly showFooter?: boolean;
  readonly selectLabel?: string;
  readonly selectAndCloseLabel?: string;
  readonly searchInputPlaceholder?: string;
  readonly elementsRef: MutableRefObject<Array<HTMLElement | null>>;
}

function getTransformOrigin(
  floatingContext: FloatingContext,
  side: string,
  isCentered: boolean
): string {
  if (isCentered) {
    return `${side} center`;
  }

  return floatingContext.placement.includes('end')
    ? `right ${side}`
    : floatingContext.placement.includes('start')
      ? `left ${side}`
      : `${side} center`;
}

export function DropdownList<V>(props: DropdownListProps<V>): ReactElement {
  const {
    floatingContext,
    searchable,
    searchInputPlaceholder,
    options,
    floatingRefs,
    floatingStyles,
    getFloatingProps,
    label,
    showFooter,
    selectLabel,
    selectAndCloseLabel,
    elementsRef,
  } = props;
  const shouldScrollIntoView = useRef(true);

  const { activeIndex, multiSelect, useOptionReferenceSize } =
    useContext(DropdownContext);
  const isCentered =
    floatingRefs.reference.current?.getBoundingClientRect().width ===
    floatingRefs.floating.current?.getBoundingClientRect().width;

  const { isMounted, styles } = useTransitionStyles(floatingContext, {
    initial: {
      transform: 'scale(0.95)',
      opacity: 0,
    },
    duration: 80,
    common: ({ side }) => ({
      transformOrigin: {
        top: getTransformOrigin(floatingContext, 'bottom', isCentered),
        bottom: getTransformOrigin(floatingContext, 'top', isCentered),
        left: '',
        right: '',
      }[side],
    }),
  });

  const { status } = useTransitionStatus(floatingContext);

  useEffect(() => {
    if (
      status === 'open' &&
      activeIndex &&
      shouldScrollIntoView.current &&
      !multiSelect
    ) {
      elementsRef.current[activeIndex]?.scrollIntoView();
      shouldScrollIntoView.current = false;
    }
  }, [status, elementsRef, activeIndex, multiSelect]);

  useEffect(() => {
    if (status === 'close') {
      shouldScrollIntoView.current = true;
    }
  }, [status]);

  const referenceWidth =
    floatingContext.refs.reference?.current?.getBoundingClientRect().width;

  return (
    <>
      <FloatingPortal>
        {isMounted && (
          <FloatingElement
            ref={floatingRefs.setFloating}
            style={floatingStyles}
            {...getFloatingProps()}
          >
            <DropdownContent style={styles}>
              <DropdownContainer
                hasLabel={Boolean(label)}
                style={
                  useOptionReferenceSize && referenceWidth
                    ? {
                        width: referenceWidth,
                      }
                    : undefined
                }
              >
                {label && (
                  <DropdownLabelWrapper>
                    <Text appearance="_12B">{label}</Text>
                  </DropdownLabelWrapper>
                )}
                {searchable && (
                  <SearchField placeholder={searchInputPlaceholder} />
                )}
                <FloatingFocusManager context={floatingContext}>
                  <FloatingList elementsRef={elementsRef}>
                    {options.length > 0 && (
                      <OptionsList>
                        <OptionsListContainer>
                          {options.map((option, idx) => (
                            <Option
                              key={
                                option.value !== undefined
                                  ? String(option.value)
                                  : idx
                              }
                              ref={node => (elementsRef.current[idx] = node)}
                              {...option}
                            />
                          ))}
                        </OptionsListContainer>
                      </OptionsList>
                    )}
                  </FloatingList>
                </FloatingFocusManager>
                {showFooter && selectLabel && selectAndCloseLabel && (
                  <Footer
                    selectLabel={selectLabel}
                    selectAndCloseLabel={selectAndCloseLabel}
                  />
                )}
              </DropdownContainer>
            </DropdownContent>
          </FloatingElement>
        )}
      </FloatingPortal>
    </>
  );
}
