import { css, keyframes, styled } from '@yarmill/component-library';

const animation = keyframes`
    0% {
      transform: translateX(-100%);
      transform-origin: left;
    }
    25% {
      transform: translateX(0%);
    }
    50% {
      transform: translateX(100%);
    }
    75% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(-100%);
    }
`;

const ProgressContainer = styled.div`
  display: block;
  position: relative;
  width: 100%;
  margin: 0;
  ${({ theme }) => css`
    height: ${theme.size.x05};
    background-color: ${theme.dark ? theme.color.brand_yarmill_dark : theme.color.brand_yarmill_35};
    border-radius: ${theme.size.x025};
    overflow: hidden;
  `}
`;
const ProgressValue = styled.div`
  width: 100%;
  inset: 0;
  position: absolute;
  animation: ${animation} 2s infinite cubic-bezier(0.69, 0.04, 0.35, 0.98);

  ${({ theme }) => css`
    background-color: ${theme.dark ? theme.color.brand_yarmill_dark : theme.color.brand_yarmill_35};
    border-radius: ${theme.size.x025};
    background-color: ${theme.dark ? theme.color.brand_yarmill_20 : theme.color.brand_yarmill};
  `};
`;

export function ProgressBar() {
  return (
    <ProgressContainer role="progressbar">
      <ProgressValue />
    </ProgressContainer>
  );
}
