import {
  css,
  loadingPlaceholderGradientDark,
  styled,
} from '@yarmill/component-library';
import { times } from '@yarmill/utils';
import { LibraryLoadingSkeletonRow } from './library-loading-skeleton-row';

const Layout = styled.div`
  width: 100%;
  max-height: 100%;
  overflow: hidden;
  mask-image: linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,0));

  ${({ theme }) => css`
    padding: ${theme.size.x1};

    & > * {
      margin-bottom: ${theme.size.x1};
    }
    & > *:nth-child(1) {
      margin-top: ${theme.size.x1};
    }
  `};
`;

const Headline = styled.div`
  width: 75%;
  ${loadingPlaceholderGradientDark};

  ${({ theme }) => css`
    height: ${theme.size.x4};
    border-radius: ${theme.borderRadius.x15};
  `};
`;

export function UploadDrawerLoadingSkeleton() {
  return (
    <Layout>
      <Headline />
      {times(6).map((_, idx) => (
        <LibraryLoadingSkeletonRow key={idx} dark />
      ))}
    </Layout>
  );
}
