import { BadgeAppearance, showToastMessage } from '@yarmill/component-library';
import { Logger } from '@yarmill/types';
import { isServerError } from '@yarmill/utils';
import { IntlShape } from 'react-intl';
import { DocumentStore } from '../stores/document-store';
import { Author, MetodejFormValues, MetodejFormik } from '../types';

const staticKeys: (keyof MetodejFormValues)[] = [
  'documentId',
  'isRead',
  'isEdited',
  'isEditable',
];
export async function handleAutoSaveFormValues(
  formik: MetodejFormik,
  documentStore: DocumentStore,
  intl: IntlShape,
  logger: Logger
): Promise<void> {
  for (const [key, value] of Object.entries(formik.values)) {
    let shouldUpdateAttribute = false;
    if (
      key === 'tags' ||
      key === 'sports' ||
      key === 'ageCategories' ||
      key === 'subjects'
    ) {
      if ((value as string[])?.length !== documentStore[key]?.length) {
        shouldUpdateAttribute = true;
      }
      if ((value as string[])?.some((v, i) => documentStore[key]?.[i] !== v)) {
        shouldUpdateAttribute = true;
      }
    } else if (key === 'authors') {
      if (
        (value as Author[]).some(
          (v, i) =>
            documentStore['authors']?.[i]?.firstName !== v.firstName ||
            documentStore['authors']?.[i]?.lastName !== v.lastName
        )
      ) {
        shouldUpdateAttribute = true;
      }
    } else if (key === 'coverPage') {
      if (
        formik.values.coverPage?.useOriginalCover !==
        documentStore.coverPage?.useOriginalCover
      ) {
        shouldUpdateAttribute = true;
      }
    } else if (key === 'abstract') {
      if (value?.abstract !== documentStore.abstract?.abstract) {
        shouldUpdateAttribute = true;
      }
    } else if (staticKeys.includes(key as keyof MetodejFormValues)) {
      shouldUpdateAttribute = false;
    } else if (documentStore[key as keyof DocumentStore] !== value) {
      shouldUpdateAttribute = true;
    }
    if (shouldUpdateAttribute) {
      documentStore.isEdited = true;
      try {
        let filteredValue = value;
        if (key === 'authors') {
          filteredValue = (filteredValue as Author[]).filter(
            author => author.firstName || author.lastName
          );
        }

        const response = await documentStore.updateAttribute(
          key as keyof DocumentStore,
          filteredValue
        );
        if (isServerError(response)) {
          if (response.Id) {
            const message = intl.formatMessage(
              { id: response.Id },
              response.Values ?? {}
            );
            formik.setFieldError(key, message);
          }
        } else if (!response) {
          showToastMessage(
            intl.formatMessage(
              { id: 'metodej.form.updateFormField.toast.error' },
              { name: key }
            ),
            BadgeAppearance.error
          );
        }
      } catch (e) {
        logger.error(e);
      }
    }
  }
}
