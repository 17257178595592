import {
  css,
  loadingPlaceholderGradientAi,
  styled,
} from '@yarmill/component-library';

const Layout = styled.div``;

const Row = styled.div<{ readonly $small?: boolean }>`
  ${loadingPlaceholderGradientAi};

  ${({ theme, $small }) => css`
    height: ${theme.size.x2};
    width: ${$small ? '40%' : '100%'};
    border-radius: ${theme.borderRadius.x1};

    & + & {
      margin-top :${theme.size.x1};
    }

  `};
`;

export function AbstractLoadingSkeleton() {
  return (
    <Layout>
      <Row />
      <Row />
      <Row $small />
    </Layout>
  );
}
