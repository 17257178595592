import { TextProps } from '@visx/text';
import { AXIS_TICK_FONT_STYLES, LABEL_OFFSET } from '../../reporting/const';

export function getAxisYTickLabelFormatter(
  angle?: number,
  width?: number
): () => Partial<TextProps> {
  return () => ({
    ...AXIS_TICK_FONT_STYLES,
    textAnchor: 'end',
    verticalAnchor: 'middle',
    dx: -LABEL_OFFSET,
    width,
    angle,
  });
}
