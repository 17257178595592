import { AsyncStatus } from '@yarmill/types';
import { observer } from 'mobx-react-lite';
import { DocumentLibraryList } from '../components/document-library-list';
import { LibraryLoadingSkeleton } from '../components/library-loading-skeleton';
import { useDocumentLibraryStore } from '../hooks/use-document-library-store';
import { DocumentList } from './document-list';
import { EmptyLibrary } from './empty-library';

export const AllDocumentsList = observer(function AllDocumentsList() {
  const libraryStore = useDocumentLibraryStore();
  const allDocuments = libraryStore.filteredDocuments;

  return allDocuments.length ? (
    <DocumentList documents={allDocuments} />
  ) : libraryStore.status === AsyncStatus.resolved ? (
    <EmptyLibrary />
  ) : (
    <DocumentLibraryList>
      <LibraryLoadingSkeleton />
    </DocumentLibraryList>
  );
});
