import { Text, css, styled } from '@yarmill/component-library';

export const HeaderContainer = styled.div`
    display: grid;
    grid-template-columns: auto 1fr;
    ${({ theme }) => css`
      column-gap: ${theme.size.x35};
      margin-bottom: ${theme.size.x35};
    `};
`;

export const DocumentTitle = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.color.neutral_neutral_darker};
    margin-bottom: ${theme.size.x1};
  `};
`;

export const DocumentSubTitle = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.color.neutral_neutral_darker};
    margin-bottom: ${theme.size.x2};
  `};
`;

export const Author = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.color.neutral_neutral};
  `};
`;

export const AbstractText = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.color.neutral_neutral_darker};
  `};
`;
