import {
  TextAreaInput,
  TextInput,
  TextInputAppearance,
} from '@yarmill/component-library';
import { useLogger } from '@yarmill/utils';
import { useField, useFormikContext } from 'formik';
import { observer } from 'mobx-react-lite';
import { useContext, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { DocumentFormContext } from '../context/document-form-context';
import { MetodejFormValues, MetodejFormik } from '../types';
import { handleAutoSaveFormValues } from '../utils/handle-auto-save-form-values';

interface TextFieldProps {
  readonly name: string;
  readonly label?: string;
  readonly noLabel?: boolean;
  readonly required?: boolean;
  readonly labelHint?: boolean;
  readonly asTextarea?: boolean;
}
export const TextField = observer(function TextField({
  name,
  label,
  labelHint,
  required,
  noLabel,
  asTextarea,
}: TextFieldProps) {
  const intl = useIntl();
  const formik = useFormikContext<MetodejFormValues>();
  const { documentType } = formik.values;
  const submitTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);
  const documentForm = useContext(DocumentFormContext);
  const autoSave = documentForm?.autoSave;
  const documentStore = documentForm?.documentStore;
  const logger = useLogger();

  const [inputProps, meta] = useField({
    name: name,
    validate: !required
      ? undefined
      : (value: string) =>
          !value
            ? intl.formatMessage(
                {
                  id: 'metodej.document.form.field.error.required',
                },
                { name }
              )
            : undefined,
  });

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    if (autoSave && documentStore) {
      if (submitTimeout.current) {
        clearTimeout(submitTimeout.current);
      }
      submitTimeout.current = setTimeout(() => {
        if (documentStore) {
          handleAutoSaveFormValues(
            formik as MetodejFormik,
            documentStore,
            intl,
            logger
          );
        }
      }, 1000);
    }
  }, [inputProps.value, logger, autoSave, documentStore]);

  const Component = asTextarea ? TextAreaInput : TextInput;

  return (
    <Component
      {...inputProps}
      appearance={
        meta.error ? TextInputAppearance.Error : TextInputAppearance.Tinted
      }
      label={
        noLabel
          ? undefined
          : (label ??
            intl.formatMessage(
              {
                id: `metodej.document.form.field.${name}.label`,
              },
              {
                documentType,
              }
            ))
      }
      aria-label={
        noLabel
          ? (label ??
            intl.formatMessage(
              {
                id: `metodej.document.form.field.${name}.ariaLabel`,
              },
              {
                documentType,
              }
            ))
          : undefined
      }
      labelHint={
        labelHint
          ? intl.formatMessage({
              id: `metodej.document.form.field.${name}.labelHint`,
            })
          : undefined
      }
      name={name}
      id={name}
      error={meta.error ? meta.error : undefined}
      hint={
        required
          ? intl.formatMessage(
              { id: 'metodej.document.form.field.required' },
              { name }
            )
          : undefined
      }
    />
  );
});
