import {
  AxisValue,
  FloatingBarChart,
  FloatingBarPosition,
  LineChart,
  MultiLineChart,
  TooltipData,
  TooltipValue,
} from '@yarmill/components';
import { DATE_FORMAT, ISO_DATE_FORMAT } from '@yarmill/const';
import { computed, makeObservable } from 'mobx';
import moment from 'moment';
import { ReactNode } from 'react';
import {
  ChartReportDataItem,
  DataDefinitionV2,
  NumberLike,
  ReportItem,
} from '../types';
import {
  createLabelFormatter,
  findClosetsNumericItem,
  findClosetsTimeItem,
} from '../utils';
import { BaseReportStore } from './base-report-store';
import { XyChartStore } from './xy-chart-store';

export class ContinuousChartStore extends BaseReportStore {
  private readonly _xyChartStore: XyChartStore;
  private readonly _isMultiLine: boolean;

  constructor(item: ReportItem, pageCode: string) {
    super(item);
    this._xyChartStore = new XyChartStore(item, pageCode);
    this._isMultiLine = Boolean(this._xyChartStore.category);
    makeObservable(this);
  }

  public get xy(): XyChartStore {
    return this._xyChartStore;
  }

  public get isMultiLine(): boolean {
    return this._isMultiLine;
  }

  @computed
  public get chartElementsConfig(): (LineChart | MultiLineChart)[] {
    return this.xy.dataColumns
      .map(def => {
        switch (def.VisualizationType) {
          case 'line':
            return this.getLineConfig(def);
          case 'multi-line':
            return this.getMultiLineConfig(def);
          case 'floating-bar':
            return this.getFloatingBarConfig(def);
          default:
            return null;
        }
      })
      .filter(Boolean) as (LineChart | MultiLineChart)[];
  }

  public get aspectRatio(): number | undefined {
    return this._item.Params?.AspectRatio;
  }

  public get backgroundImage(): string | undefined {
    return this._item.Params?.BackgroundImage;
  }

  private getLineConfig(def: DataDefinitionV2): LineChart {
    const axisDataType = this.xy.axisX?.DataType?.toLowerCase();

    const baseConfig: Partial<LineChart> = {
      ...this.xy.baseConfig,
      type: 'Line',
      code: def.Name,
      formatLabelValue: createLabelFormatter(def.BusinessFormat, def.Format),
      getCurveType: item => this.xy.getCurveType(item, def.Name),
      getYValue: item => item[def.Name] as number | null,
      getLineStyle: (item: ChartReportDataItem) =>
        this.xy.getLineStyle(item, def.Name),
      getColor: (item: ChartReportDataItem) => this.xy.getColor(item, def.Name),
      getLabelColor: (item: ChartReportDataItem) =>
        this.xy.getLabelColor(item, def.Name),
      getStrokeWidth: item => this.xy.getStrokeWidth(item, def),
      getMarkerSize: item => this.xy.getMarkerSize(item, def.Name),
      getOpacity: item => this.xy.getOpacity(item, def.Name),
      getStrokeDasharray: item => this.xy.getStrokeDasharray(item, def),
      getShowLabels: (item: ChartReportDataItem): boolean =>
        this.xy.getShowLabels(item, def.Name),
      getMarkerStrokeWidth: (item: ChartReportDataItem) =>
        this.xy.getMarkerStrokeWidth(item, def),
      getMarkerStrokeColor: (item: ChartReportDataItem) =>
        this.xy.getMarkerStrokeColor(item, def),
      getMarkerColor: (item: ChartReportDataItem) =>
        this.xy.getMarkerColor(item, def.Name),
    };

    if (axisDataType === 'date') {
      return {
        ...baseConfig,
        getXValue: (x: ChartReportDataItem): AxisValue =>
          moment(this.xy.getXValue(x)).toDate(),
      } as LineChart;
    } else {
      return {
        ...baseConfig,
        getXValue: (x: ChartReportDataItem): AxisValue => this.xy.getXValue(x),
      } as LineChart;
    }
  }

  private getMultiLineConfig(def: DataDefinitionV2): MultiLineChart {
    const axisDataType = this.xy.axisX?.DataType?.toLowerCase();

    const baseConfig: Partial<MultiLineChart> = {
      ...this.xy.baseConfig,
      type: 'MultiLine',
      code: def.Name,
      formatLabelValue: createLabelFormatter(def.BusinessFormat, def.Format),
      getXValue: (x: ChartReportDataItem): AxisValue =>
        moment(this.xy.getXValue(x)).toDate(),
      getYValue: item => item[def.Name] as number | null,
      getLineStyle: item => this.xy.getLineStyle(item, def.Name),
      getCurveType: item => this.xy.getCurveType(item, def.Name),
      getMarkerSize: item => this.xy.getMarkerSize(item, def.Name),
      categoryKey: this.xy.category?.Name ?? '',
      getStrokeWidth: item => this.xy.getStrokeWidth(item, def),
      getOpacity: (item, key) => this.xy.getOpacity(item, key),
      getStrokeDasharray: item => this.xy.getStrokeDasharray(item, def),
      getShowLabels: (item: ChartReportDataItem): boolean =>
        this.xy.getShowLabels(item, def.Name),
      getLineOrder: (item: ChartReportDataItem) => this.xy.getLineOrder(item),
      getMarkerColor: (item: ChartReportDataItem) =>
        this.xy.getMarkerColor(item, def.Name),
    };

    if (axisDataType === 'date') {
      return {
        ...baseConfig,
        getXValue: (x: ChartReportDataItem): Date =>
          moment(this.xy.getXValue(x)).toDate(),
      } as MultiLineChart;
    } else {
      return {
        ...baseConfig,
        getXValue: (x: ChartReportDataItem): number => this.xy.getXValue(x),
      } as MultiLineChart;
    }
  }

  private getFloatingBarConfig(def: DataDefinitionV2): FloatingBarChart {
    const axisDataType = this.xy.axisX?.DataType?.toLowerCase();

    const baseConfig: Partial<FloatingBarChart> = {
      ...this.xy.baseConfig,
      type: 'FloatingBar',
      code: def.Name,
      formatLabelValue: createLabelFormatter(def.BusinessFormat, def.Format),
      highlightAttribute: def.HighlightAttribute ?? null,
      getYValue: (item: ChartReportDataItem) => item[def.Name] as number,
      getColor: (item: ChartReportDataItem) => this.xy.getColor(item, def.Name),
      getLabelColor: (item: ChartReportDataItem) =>
        this.xy.getLabelColor(item, def.Name),
      getShowLabels: (item: ChartReportDataItem): boolean =>
        this.xy.getShowLabels(item, def.Name),
      getBarWidth: (item: ChartReportDataItem): number =>
        this.xy.getWidth(item, def.Name),
      getOpacity: (item: ChartReportDataItem): number | undefined =>
        this.xy.getOpacity(item, def.Name),
      getLabelAngle: (item: ChartReportDataItem): number =>
        this.xy.getLabelAngle(item, def.Name),
      getBarPosition: (item: ChartReportDataItem): FloatingBarPosition =>
        this.xy.getBarPosition(item, def.Name),
      getHighlightColor: (item: ChartReportDataItem): string | null =>
        this.xy.getHighlightColor(item, def.Name),
    };

    if (axisDataType === 'date') {
      return {
        ...baseConfig,
        getXValue: (x: ChartReportDataItem): AxisValue =>
          moment(this.xy.getXValue(x)).toDate(),
      } as FloatingBarChart;
    } else {
      return {
        ...baseConfig,
        getXValue: (x: ChartReportDataItem): AxisValue => this.xy.getXValue(x),
      } as FloatingBarChart;
    }
  }

  public readonly getTooltipData = (
    xValue: Date | number,
    data: ChartReportDataItem[],
    groupedData: { [key: string]: ChartReportDataItem[] },
    exactDate: boolean,
    tolerance: number
  ): TooltipData => {
    const [values, label] = this._isMultiLine
      ? this.getMultiLineTooltipData(xValue, groupedData, exactDate, tolerance)
      : this.getCombinedTooltipData(xValue, data, exactDate, tolerance);

    return {
      key: String(xValue),
      values,
      showLabel: this.xy.showAxisXLabelsInTooltip,
      showEmptyTooltip: Boolean(this._item.Params?.ShowEmptyTooltip),
      label,
      ...this.xy.tooltipTableLayoutConfig,
    };
  };

  private getCombinedTooltipData(
    xValue: Date | number,
    data: ChartReportDataItem[],
    exactDate: boolean,
    tolerance: number
  ): [TooltipValue[], ReactNode] {
    let item;
    const axisXKey = this.xy.axisX?.Name;
    if (!axisXKey) {
      return [[], ''];
    }

    const stringValue = moment(xValue).format(ISO_DATE_FORMAT);
    const isDate = this.xy.axisX?.DataType === 'date';

    if (exactDate) {
      item = data.find(item =>
        isDate
          ? moment(item[axisXKey]).format(ISO_DATE_FORMAT) === stringValue
          : item[axisXKey] === xValue
      );
    } else {
      item = isDate
        ? findClosetsTimeItem(data, axisXKey, xValue as Date, tolerance)
        : findClosetsNumericItem(data, axisXKey, xValue as number, tolerance);
    }

    return [
      item ? this.xy.mapTooltipValues(item, 'x') : [],
      this.xy.formatTooltipLabel(
        isDate
          ? moment(item?.[axisXKey]).format(DATE_FORMAT)
          : (xValue as number),
        'x',
        item || undefined
      ),
    ];
  }

  private getMultiLineTooltipData(
    xValue: Date | number,
    data: { [key: string]: ChartReportDataItem[] },
    exactDate: boolean,
    tolerance: number
  ): [TooltipValue[], ReactNode] {
    const axisXKey = this.xy.axisX?.Name;
    if (!axisXKey) {
      return [[], ''];
    }

    const stringValue = moment(xValue).format(ISO_DATE_FORMAT);
    const isDate = this.xy.axisX?.DataType === 'date';

    let items: ChartReportDataItem[] = Object.values(data)
      .map(lineData => {
        if (exactDate) {
          return lineData.find(item =>
            isDate
              ? moment(item[axisXKey]).format(ISO_DATE_FORMAT) === stringValue
              : item[axisXKey] === xValue
          );
        } else {
          return isDate
            ? findClosetsTimeItem(lineData, axisXKey, xValue as Date, tolerance)
            : findClosetsNumericItem(
                lineData,
                axisXKey,
                xValue as number,
                tolerance
              );
        }
      })
      .filter(Boolean) as ChartReportDataItem[];

    if (!exactDate && items.length > 1) {
      items = items
        .sort((a, b) => {
          const aDate = Math.abs(
            isDate
              ? xValue.valueOf() - moment(a[axisXKey]).toDate().valueOf()
              : (xValue as number) - (a[axisXKey] as number)
          );
          const bDate = Math.abs(
            isDate
              ? xValue.valueOf() - moment(b[axisXKey]).toDate().valueOf()
              : (xValue as number) - (b[axisXKey] as number)
          );
          return aDate - bDate;
        })
        .filter(i => i[axisXKey] === items[0][axisXKey]);
    }

    return [
      this.xy.mapMultiLineTooltipValues(items, 'x'),
      this.xy.formatTooltipLabel(
        isDate
          ? moment(items?.[0]?.[axisXKey]).format(DATE_FORMAT)
          : (xValue as number),
        'x',
        items?.[0]
      ),
    ];
  }

  public readonly formatAxisXTick = (tickValue: Date | NumberLike): string => {
    if (tickValue instanceof Date) {
      return this.xy.formatAxisXTick(moment(tickValue).format(ISO_DATE_FORMAT));
    }

    return String(tickValue);
  };
}
