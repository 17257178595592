import {
  ReportsContainer,
  SharedTooltipContextProvider,
} from '@yarmill/components';
import { useHelmetTitle } from '@yarmill/utils';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import { Filters } from './filters';
import { useReportingStore } from './hooks/use-reporting-store';
import { Report } from './report';
import { Section } from './section';

export interface ReportsProps {
  showFilters?: boolean;
}

export const Reports = observer(function Reports(
  props: ReportsProps
): JSX.Element {
  const { showFilters = true } = props;
  const reportingStore = useReportingStore();
  const reports = reportingStore.pageReports;
  const sections = reportingStore.pageSections;
  const currentPage = reportingStore.currentPage?.code;
  const intl = useIntl();
  const helmetTitle = useHelmetTitle([
    reportingStore.currentPage?.title
      ? intl.formatMessage({ id: reportingStore.currentPage.title })
      : null,
    intl.formatMessage({ id: 'header.navigation.analytics' }), // TODO replace with analytics
  ]);

  useEffect(() => {
    if (currentPage) {
      document
        .querySelector('.sticky-content')
        ?.scroll({ top: 0, behavior: 'auto' });
    }
  }, [currentPage]);

  return (
    <SharedTooltipContextProvider>
      <Helmet>
        <title>{helmetTitle}</title>
      </Helmet>
      <ReportsContainer>
        {showFilters && <Filters position="page" />}
        {!sections?.length &&
          reports.map(report => <Report report={report} key={report.code} />)}
        {sections
          ?.filter(section => !section.ParentSection)
          ?.map((section, idx) => (
            <Section
              key={`${reportingStore.currentPage?.code}-${idx}`}
              sectionDefinition={section}
              subSections={sections?.filter(
                subSection =>
                  subSection.ParentSection &&
                  subSection.ParentSection === section.Code
              )}
            />
          ))}
      </ReportsContainer>
    </SharedTooltipContextProvider>
  );
});
