import { useMergeRefs } from '@floating-ui/react';
import { Tippy } from '@yarmill/components';
import { useTextAreaAutosize } from '@yarmill/utils';
import {
  ReactElement,
  TextareaHTMLAttributes,
  forwardRef,
  useEffect,
  useRef,
} from 'react';
import { Badge, BadgeAppearance } from './badge';
import { FormInputError } from './form-input-error';
import { FormInputHint } from './form-input-hint';
import { FormInputLabel } from './form-input-label';
import { FormInputLayout } from './form-input-layout';
import {
  IconWrapper,
  StyledTextInput,
  TextInputAppearance,
  TextInputWrapper,
} from './text-input';
import { styled } from './theme-provider';

interface TextAreaInputProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  readonly value?: string;
  readonly defaultValue?: string;
  readonly label?: string;
  readonly labelHint?: string;
  readonly align?: 'left' | 'right';
  readonly icon?: ReactElement;
  readonly name: string;
  readonly appearance?: TextInputAppearance;
  readonly id: string;
  readonly hint?: string;
  readonly error?: string;
}

const StyledTextArea = styled(StyledTextInput)`
  resize: none;
  overflow-y: hidden;
`;

export const TextAreaInput = forwardRef<
  HTMLTextAreaElement,
  TextAreaInputProps
>(function TextAreaInput(
  {
    label,
    labelHint,
    align = 'left',
    appearance = TextInputAppearance.Neutral,
    icon,
    hint,
    error,
    autoFocus,
    ...textAreaProps
  },
  ref
): ReactElement {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  useTextAreaAutosize(textareaRef);

  useEffect(() => {
    if (autoFocus) {
      textareaRef.current?.focus();
    }
  }, [autoFocus]);

  const textAreaRef = useMergeRefs([ref, textareaRef]);

  return (
    <FormInputLayout>
      {label && (
        <FormInputLabel htmlFor={textAreaProps.id} align={align}>
          {label}
          {labelHint && (
            <Tippy tooltipContent={labelHint} translateValue={false}>
              <Badge size={12} appearance={BadgeAppearance.hint} />
            </Tippy>
          )}
        </FormInputLabel>
      )}
      <TextInputWrapper>
        <StyledTextArea
          {...textAreaProps}
          rows={1}
          as="textarea"
          $appearance={appearance}
          align={align}
          ref={textAreaRef}
          hasIcon={Boolean(icon)}
          value={textAreaProps.value === null ? '' : textAreaProps.value}
        />
        {icon && <IconWrapper $appearance={appearance}>{icon}</IconWrapper>}
      </TextInputWrapper>
      {hint && !error && <FormInputHint>{hint}</FormInputHint>}
      {error && <FormInputError>{error}</FormInputError>}
    </FormInputLayout>
  );
});
