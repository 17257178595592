import { AthletesSidebar, PageMainContent } from '@yarmill/components';
import {
  useCurrentUserStore,
  useHelmetTitle,
  useRootStore,
} from '@yarmill/utils';
import { observer } from 'mobx-react-lite';
import { memo } from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import { Redirect } from 'react-router-dom';
import { PageContent } from './page-content';
import {
  SeasonEvaluationStoreContextProvider,
  useSeasonEvaluationStore,
} from './season-evaluation-store-context';

function InternalSeasonEvaluationRouteRoot(): JSX.Element {
  const rootStore = useRootStore();
  const currentUser = useCurrentUserStore();
  const seasonEvaluationStore = useSeasonEvaluationStore();
  const intl = useIntl();
  const helmetTitle = useHelmetTitle([
    seasonEvaluationStore.athlete?.displayName ??
      seasonEvaluationStore.group?.name,
    intl.formatMessage({ id: 'header.navigation.seasonEvaluation' }),
  ]);

  if (
    rootStore.isReady &&
    !currentUser.isAthlete &&
    !seasonEvaluationStore.groupId &&
    !seasonEvaluationStore.athleteId
  ) {
    return <Redirect to="/noGroup" />;
  }

  return (
    <>
      <Helmet>
        <title>{helmetTitle}</title>
      </Helmet>
      {currentUser.role !== 'athlete' ? (
        <AthletesSidebar
          disableWholeGroup={
            seasonEvaluationStore.viewType === 'season-over-season'
          }
          disableWholeGroupTooltip="sidebar.seasonEvaluation.disableWholeGroupForSos"
        />
      ) : undefined}
      <PageMainContent>
        <PageContent />
      </PageMainContent>
    </>
  );
}

const ObserverSeasonEvaluationRouteRoot = observer(
  InternalSeasonEvaluationRouteRoot
);

export const SeasonEvaluationModule = memo(() => (
  <SeasonEvaluationStoreContextProvider>
    <ObserverSeasonEvaluationRouteRoot />
  </SeasonEvaluationStoreContextProvider>
));
