import {
  css,
  loadingPlaceholderGradient,
  loadingPlaceholderGradientDark,
  styled,
} from '@yarmill/component-library';

const Layout = styled.div<LibraryLoadingSkeletonRowProps>`
  display: grid;
  grid-template-columns: auto 1fr;

  ${({ theme, dark }) => css`
    column-gap: ${theme.size.x25};
    row-gap: ${theme.size.x1};
    padding: ${theme.size.x2};
    background-color: ${dark ? theme.color.background_background_03 : theme.color.background_background_02};
    border-radius: ${theme.borderRadius.x15};
  `};
`;

const Cover = styled.div<LibraryLoadingSkeletonRowProps>`
  width: 50px;
  aspect-ratio: 50/66;
  grid-column: 1/2;
  grid-row: 1/3;

   ${({ theme, dark }) => css`
     ${dark ? loadingPlaceholderGradientDark : loadingPlaceholderGradient};
     border-radius: ${theme.size.x025};
   `}
`;

const FirstRow = styled.div<LibraryLoadingSkeletonRowProps>`
  grid-column: 2 / 3;
  grid-row: 1 / 2;

  ${({ theme, dark }) => css`
    ${dark ? loadingPlaceholderGradientDark : loadingPlaceholderGradient};
    height: ${theme.size.x4};
    border-radius: ${theme.borderRadius.x15};
  `};
`;

const SecondRow = styled.div<LibraryLoadingSkeletonRowProps>`
  grid-column: 2 / 3;
  grid-row: 2 / 3;
  width: 50%;

  ${({ theme, dark }) => css`
   ${dark ? loadingPlaceholderGradientDark : loadingPlaceholderGradient};
    height: ${theme.size.x25};
    border-radius: ${theme.borderRadius.x1};
  `};
`;

interface LibraryLoadingSkeletonRowProps {
  readonly dark?: boolean;
}
export function LibraryLoadingSkeletonRow({
  dark,
}: LibraryLoadingSkeletonRowProps) {
  return (
    <Layout dark={dark}>
      <Cover dark={dark} />
      <FirstRow dark={dark} />
      <SecondRow dark={dark} />
    </Layout>
  );
}
