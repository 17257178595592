import { Button, ButtonAppearance } from '@yarmill/component-library';
import { Minus, Plus } from '@yarmill/icon-library';
import { FieldArray, useFormikContext } from 'formik';
import { observer } from 'mobx-react-lite';
import { Fragment } from 'react';
import { useIntl } from 'react-intl';
import { DocumentFormSectionRow } from '../components';
import {
  AdditionalAuthorLastNameWrapper,
  AuthorsFieldLayout,
} from '../components/authors-field-layout';
import { MetodejDocument } from '../types';
import { TextField } from './text-field';

export const AuthorsField = observer(function AuthorsField() {
  const intl = useIntl();
  const formik = useFormikContext<MetodejDocument>();
  const authors = formik.values.authors;
  const documentType = formik.values.documentType;
  const additionalAuthors = authors.slice(1);

  return (
    <DocumentFormSectionRow columns={2}>
      <TextField
        label={intl.formatMessage({
          id: `metodej.document.form.field.authors.firstName.label`,
        })}
        name="authors[0].firstName"
        required
      />
      <TextField
        label={intl.formatMessage({
          id: `metodej.document.form.field.authors.lastName.label`,
        })}
        name="authors[0].lastName"
        required
      />
      <FieldArray name="authors">
        {({ remove, push }) => (
          <>
            <AuthorsFieldLayout>
              {additionalAuthors.map((_, index) => (
                <Fragment key={index}>
                  <TextField
                    name={`authors[${index + 1}].firstName`}
                    noLabel
                    label={intl.formatMessage(
                      {
                        id: `metodej.document.form.field.authors.firstName.ariaLabel`,
                      },
                      {
                        documentType,
                        index: index + 2,
                      }
                    )}
                  />
                  <AdditionalAuthorLastNameWrapper>
                    <TextField
                      name={`authors[${index + 1}].lastName`}
                      noLabel
                      label={intl.formatMessage(
                        {
                          id: `metodej.document.form.field.authors.lastName.ariaLabel`,
                        },
                        {
                          documentType,
                          index: index + 2,
                        }
                      )}
                    />
                    <Button
                      type="button"
                      appearance={ButtonAppearance.Secondary}
                      icon={<Minus />}
                      onClick={() => remove(index + 1)}
                      aria-label={intl.formatMessage({
                        id: 'metodej.document.form.field.authors.removeAuthorButtonLabel',
                      })}
                    />
                  </AdditionalAuthorLastNameWrapper>
                </Fragment>
              ))}
            </AuthorsFieldLayout>
            <div>
              <Button
                type="button"
                appearance={ButtonAppearance.Secondary}
                onClick={() => push({ firstName: '', lastName: '' })}
                icon={<Plus />}
              >
                {intl.formatMessage({
                  id: 'metodej.document.form.field.authors.addAuthorButtonLabel',
                })}
              </Button>
            </div>
          </>
        )}
      </FieldArray>
    </DocumentFormSectionRow>
  );
});
