import {
  AxisValue,
  BarGroupChart,
  HorizontalBarGroupChart,
  TooltipData,
} from '@yarmill/components';
import { computed, makeObservable } from 'mobx';
import { ChartReportDataItem, ReportItem } from '../types';
import { createBarGroupLabelFormatter } from '../utils';
import { BaseReportStore } from './base-report-store';
import { XyChartStore } from './xy-chart-store';

export class ClusteredColumnChartStore extends BaseReportStore {
  private readonly _xyChartStore: XyChartStore;

  constructor(item: ReportItem, pageCode: string) {
    super(item);
    makeObservable(this);
    this._xyChartStore = new XyChartStore(item, pageCode);
  }

  public get xy(): XyChartStore {
    return this._xyChartStore;
  }

  @computed
  public get chartElementsConfig():
    | [BarGroupChart]
    | [HorizontalBarGroupChart] {
    return [
      {
        ...this.xy.baseConfig,
        type:
          this.item.Kind === 'HorizontalClusteredColumnChart'
            ? 'HorizontalBarGroup'
            : 'BarGroup',
        code: this.code,
        keys: this.xy.dataColumns.map(def => def.Name),
        formatLabelValue: createBarGroupLabelFormatter(
          this.xy.dataColumnsByName
        ),
        getShowLabels: (item: ChartReportDataItem, name: string) =>
          this.xy.getShowLabels(item, name),
        getBarWidth: (item: ChartReportDataItem, name: string): number =>
          this.xy.getWidth(item, name),
        getOpacity: (
          item: ChartReportDataItem,
          name: string
        ): number | undefined => this.xy.getOpacity(item, name),
        getLabelAngle: (item: ChartReportDataItem, name: string): number =>
          this.xy.getLabelAngle(item, name),
      },
    ] as [BarGroupChart] | [HorizontalBarGroupChart];
  }

  public readonly getTooltipData = (
    value: AxisValue,
    data: ChartReportDataItem[],
    mainAxis: 'x' | 'y'
  ): TooltipData => {
    const item = data.find(
      d =>
        (mainAxis === 'x' ? this.xy.getXValue(d) : this.xy.getYValue(d)) ===
        value
    );
    const values = item ? this.xy.mapTooltipValues(item, mainAxis) : [];

    return {
      key: String(value),
      values,
      showLabel:
        mainAxis === 'x'
          ? this.xy.showAxisXLabelsInTooltip
          : this.xy.showAxisYLabelsInTooltip,
      showEmptyTooltip: Boolean(this._item.Params?.ShowEmptyTooltip),
      label: this.xy.formatTooltipLabel(value, mainAxis, item),
      ...this.xy.tooltipTableLayoutConfig,
    };
  };
}
