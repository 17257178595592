import {
  Canvas,
  CanvasLayout,
  PanelTitle,
  PanelToolbar,
  Tag,
  TagAppearance,
} from '@yarmill/component-library';
import { Yollanda } from '@yarmill/icon-library';
import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import { useRouteMatch } from 'react-router-dom';
import { DetailBackButton } from '../buttons/detail-back-button';
import { DetailEditButton } from '../buttons/detail-edit-button';
import { ToggleSideMenu } from '../buttons/toggle-side-menu';
import { DocumentCoverSize } from '../components';
import { AutomaticDocumentCover } from '../components/automatic-document-cover';
import {
  AbstractText,
  Author,
  DocumentSubTitle,
  DocumentTitle,
  HeaderContainer,
} from '../components/document-detail';
import { DocumentDetailCategory } from '../components/document-detail-category';
import { DocumentDetailTagsCategory } from '../components/document-detail-tags-category';
import { LibraryCanvasContainer } from '../components/library-canvas-container';
import { useDocumentLibraryStore } from '../hooks/use-document-library-store';
import { DocumentDetailCiteSection } from './document-detail-cite-section';
import { Tags } from './tags';

export const DocumentDetail = observer(function DocumentDetail() {
  const libraryStore = useDocumentLibraryStore();
  const {
    params: { id },
  } = useRouteMatch<{ readonly id: string }>();
  const intl = useIntl();
  const document = id ? libraryStore.documentsById.get(Number(id)) : null;

  return (
    <CanvasLayout>
      <Canvas
        header={
          <PanelToolbar
            left={
              <>
                <ToggleSideMenu />
                <DetailBackButton />
              </>
            }
            center={
              <PanelTitle
                text={intl.formatMessage(
                  {
                    id: 'metodej.library.canvas.title.documentDetail',
                  },
                  { documentType: document?.documentType }
                )}
              />
            }
            right={document?.isEditable ? <DetailEditButton /> : undefined}
          />
        }
        footer={<PanelToolbar position="footer" />}
      >
        <LibraryCanvasContainer>
          {document ? (
            <>
              <HeaderContainer>
                <AutomaticDocumentCover
                  documentStore={document}
                  size={DocumentCoverSize.medium}
                />
                <div>
                  <DocumentTitle appearance="_16B" as="h1">
                    {document.title}
                  </DocumentTitle>
                  <DocumentSubTitle appearance="_12M" as="p">
                    {document.subTitle}
                  </DocumentSubTitle>
                  <Author appearance="_12M" as="p">
                    {document.authors
                      .map(a => `${a.firstName} ${a.lastName}`)
                      .join(', ')}
                  </Author>
                </div>
              </HeaderContainer>
              {Boolean(
                document.sports?.length ||
                  document.subjects?.length ||
                  document.ageCategories?.length ||
                  document.tags?.length
              ) && (
                <DocumentDetailTagsCategory
                  headline={intl.formatMessage({
                    id: 'metodej.library.detail.categories.headline',
                  })}
                  sportHeadline={
                    document.sports?.length
                      ? intl.formatMessage({
                          id: 'metodej.library.detail.categories.sports.headline',
                        })
                      : undefined
                  }
                  sportTags={
                    <Tags tags={document.sports ?? []} type="sports" />
                  }
                  subjectHeadline={
                    document.subjects?.length
                      ? intl.formatMessage({
                          id: 'metodej.library.detail.categories.subject.headline',
                        })
                      : undefined
                  }
                  subjectTags={
                    <Tags tags={document.subjects ?? []} type="subjects" />
                  }
                  ageHeadline={
                    document.ageCategories?.length
                      ? intl.formatMessage({
                          id: 'metodej.library.detail.categories.age.headline',
                        })
                      : undefined
                  }
                  ageTags={
                    <Tags
                      tags={document.ageCategories ?? []}
                      type="ageCategories"
                    />
                  }
                  generalTagsHeadline={
                    document.tags?.length
                      ? intl.formatMessage({
                          id: 'metodej.library.detail.categories.tags.headline',
                        })
                      : undefined
                  }
                  generalTags={<Tags tags={document.tags ?? []} type="tags" />}
                />
              )}
              {document.abstract?.abstract && (
                <DocumentDetailCategory
                  headline={
                    <>
                      {intl.formatMessage({
                        id: 'metodej.library.detail.abstract.headline',
                      })}
                      {document.abstract?.isGenerated && (
                        <Tag
                          text={intl.formatMessage({
                            id: 'metodej.library.detail.abstract.headline.aiGeneratedBadgeText',
                          })}
                          appearance={TagAppearance.brand}
                          icon={<Yollanda />}
                        />
                      )}
                      {!document.abstract?.isGenerated &&
                        document.abstract?.isTranslated && (
                          <Tag
                            text={intl.formatMessage({
                              id: 'metodej.library.detail.abstract.headline.aiTranslatedBadgeText',
                            })}
                            appearance={TagAppearance.brand}
                            icon={<Yollanda />}
                          />
                        )}
                    </>
                  }
                >
                  <AbstractText appearance="_12MText" whiteSpace="pre-wrap">
                    {document.abstract?.abstract}
                  </AbstractText>
                </DocumentDetailCategory>
              )}
              <DocumentDetailCiteSection documentStore={document} />
            </>
          ) : libraryStore.allDocuments.length ? (
            <DocumentTitle appearance="_16B">
              {intl.formatMessage({
                id: 'metodej.library.detail.documentNotFound',
              })}
            </DocumentTitle>
          ) : null}
        </LibraryCanvasContainer>
      </Canvas>
    </CanvasLayout>
  );
});
