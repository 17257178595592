import { ThemeProvider } from '@yarmill/components';
import {
  AppContainer,
  PageContentWrapper,
  PrimarySidebar,
} from '@yarmill/components-2';
import {
  useCurrentUserStore,
  useHelmetTitle,
  useRootStore,
  useSidebarStore,
} from '@yarmill/utils';
import { observer } from 'mobx-react-lite';
import { memo } from 'react';
import Helmet from 'react-helmet';
import { useIntl } from 'react-intl';
import { Redirect } from 'react-router-dom';
import { Planner } from './planner';
import {
  PlannerStoreContextProvider,
  usePlannerStore,
} from './planner-store-context';

function InternalPlannerRouteRoot(): JSX.Element | null {
  const rootStore = useRootStore();
  const sidebarStore = useSidebarStore();
  const plannerStore = usePlannerStore();
  const currentUser = useCurrentUserStore();
  const intl = useIntl();
  const helmetTitle = useHelmetTitle([
    plannerStore.athlete?.displayName ?? plannerStore.group?.name,
    intl.formatMessage({ id: 'header.navigation.planner' }),
  ]);

  if (
    rootStore.isReady &&
    !currentUser.isAthlete &&
    !plannerStore.group &&
    (!plannerStore.athleteId || plannerStore.athleteId === currentUser.id)
  ) {
    return <Redirect to="/noGroup" />;
  }

  return rootStore.isReady ? (
    <>
      <Helmet>
        <title>{helmetTitle}</title>
      </Helmet>
      <AppContainer>
        <PrimarySidebar />
        <PageContentWrapper isSidebarVisible={sidebarStore.isVisible}>
          <Planner />
        </PageContentWrapper>
      </AppContainer>
    </>
  ) : null;
}

const ObserverPlannerRouteRoot = observer(InternalPlannerRouteRoot);

export const PlannerModule = memo(() => (
  <PlannerStoreContextProvider>
    <ThemeProvider theme="main">
      <ObserverPlannerRouteRoot />
    </ThemeProvider>
  </PlannerStoreContextProvider>
));
