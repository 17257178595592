import { computed, makeObservable, observable } from 'mobx';
import { api } from '../metodej-api';
import {
  Abstract,
  Author,
  CoverPage,
  DocumentFormat,
  DocumentType,
  MetodejDocument,
  ThesisType,
} from '../types';

export class DocumentStore implements MetodejDocument {
  @observable
  private _abstract: Abstract | null;
  @observable
  private _ageCategories: string[];
  @observable
  private _authors: Author[];
  @observable
  private _coverPage: CoverPage;
  @observable
  private _documentFormat: DocumentFormat | null;
  @observable
  private _documentId: number;
  @observable
  private _documentType: DocumentType | null;
  @observable
  private _doi: string | null;
  @observable
  private _edition: string | null;
  @observable
  private _fileName: string | null;
  @observable
  private _hostEntity: string | null;
  @observable
  private _isAnnotated: boolean;
  @observable
  private _isEditable: boolean;
  @observable
  private _isEdited: boolean;
  @observable
  private _isRead: boolean;
  @observable
  private _isbn: string | null;
  @observable
  private _issn: string | null;
  @observable
  private _languageCode: string | null;
  @observable
  private _medium: string | null;
  @observable
  private _pagesCount: number;
  @observable
  private _publicationPlace: string | null;
  @observable
  private _publicationYear: number;
  @observable
  private _publisher: string | null;
  @observable
  private _sports: string[];
  @observable
  private _subTitle: string | null;
  @observable
  private _subjects: string[];
  @observable
  private _tags: string[];
  @observable
  private _thesisInstitution: string | null;
  @observable
  private _thesisSupervisor: string | null;
  @observable
  private _thesisType: ThesisType | null;
  @observable
  private _title: string | null;
  @observable
  private _url: string | null;

  constructor(data: MetodejDocument) {
    this._abstract = data.abstract;
    this._ageCategories = data.ageCategories;
    this._authors = data.authors;
    this._coverPage = data.coverPage;
    this._documentFormat = data.documentFormat;
    this._documentId = data.documentId;
    this._documentType = data.documentType;
    this._doi = data.doi;
    this._edition = data.edition;
    this._fileName = data.fileName;
    this._hostEntity = data.hostEntity;
    this._isbn = data.isbn;
    this._issn = data.issn;
    this._languageCode = data.languageCode;
    this._medium = data.medium;
    this._pagesCount = data.pagesCount;
    this._publicationPlace = data.publicationPlace;
    this._publicationYear = data.publicationYear;
    this._publisher = data.publisher;
    this._sports = data.sports;
    this._subTitle = data.subTitle;
    this._subjects = data.subjects;
    this._tags = data.tags;
    this._thesisInstitution = data.thesisInstitution;
    this._thesisSupervisor = data.thesisSupervisor;
    this._thesisType = data.thesisType;
    this._title = data.title;
    this._url = data.url;
    this._isAnnotated = data.isAnnotated;
    this._isEditable = data.isEditable;
    this._isEdited = data.isEdited;
    this._isRead = data.isRead;

    makeObservable(this);
  }

  get abstract(): {
    abstract: string;
    isGenerated: boolean;
    isTranslated: boolean;
  } | null {
    return this._abstract;
  }

  set abstract(value: {
    abstract: string;
    isGenerated: boolean;
    isTranslated: boolean;
  } | null) {
    this._abstract = value;
  }

  get ageCategories(): string[] {
    return this._ageCategories;
  }

  set ageCategories(value: string[]) {
    this._ageCategories = value;
  }

  get authors(): Author[] {
    return this._authors;
  }

  set authors(value: Author[]) {
    this._authors = value;
  }

  get coverPage(): CoverPage {
    return this._coverPage;
  }

  set coverPage(value: CoverPage) {
    this._coverPage = value;
  }

  get documentFormat(): DocumentFormat | null {
    return this._documentFormat;
  }

  set documentFormat(value: DocumentFormat) {
    this._documentFormat = value;
  }

  get documentId(): number {
    return this._documentId;
  }

  set documentId(value: number) {
    this._documentId = value;
  }

  get documentType(): DocumentType | null {
    return this._documentType;
  }

  set documentType(value: DocumentType) {
    this._documentType = value;
  }

  get doi(): string | null {
    return this._doi;
  }

  set doi(value: string | null) {
    this._doi = value;
  }

  get edition(): string | null {
    return this._edition;
  }

  set edition(value: string | null) {
    this._edition = value;
  }

  get fileName(): string | null {
    return this._fileName;
  }

  set fileName(value: string | null) {
    this._fileName = value;
  }

  get hostEntity(): string | null {
    return this._hostEntity;
  }

  set hostEntity(value: string | null) {
    this._hostEntity = value;
  }

  get isAnnotated(): boolean {
    return this._isAnnotated;
  }

  set isAnnotated(value: boolean) {
    this._isAnnotated = value;
  }

  get isEditable(): boolean {
    return this._isEditable;
  }

  set isEditable(value: boolean) {
    this._isEditable = value;
  }

  get isEdited(): boolean {
    return this._isEdited;
  }

  set isEdited(value: boolean) {
    this._isEdited = value;
  }

  get isRead(): boolean {
    return this._isRead;
  }

  set isRead(value: boolean) {
    this._isRead = value;
  }

  get isbn(): string | null {
    return this._isbn;
  }

  set isbn(value: string | null) {
    this._isbn = value;
  }

  get issn(): string | null {
    return this._issn;
  }

  set issn(value: string | null) {
    this._issn = value;
  }

  get languageCode(): string | null {
    return this._languageCode;
  }

  set languageCode(value: string | null) {
    this._languageCode = value;
  }

  get medium(): string | null {
    return this._medium;
  }

  set medium(value: string | null) {
    this._medium = value;
  }

  get pagesCount(): number {
    return this._pagesCount;
  }

  set pagesCount(value: number) {
    this._pagesCount = value;
  }

  get publicationPlace(): string | null {
    return this._publicationPlace;
  }

  set publicationPlace(value: string | null) {
    this._publicationPlace = value;
  }

  get publicationYear(): number {
    return this._publicationYear;
  }

  set publicationYear(value: number) {
    this._publicationYear = value;
  }

  get publisher(): string | null {
    return this._publisher;
  }

  set publisher(value: string | null) {
    this._publisher = value;
  }

  get sports(): string[] {
    return this._sports;
  }

  set sports(value: string[]) {
    this._sports = value;
  }

  get subTitle(): string | null {
    return this._subTitle;
  }

  set subTitle(value: string | null) {
    this._subTitle = value;
  }

  get subjects(): string[] {
    return this._subjects;
  }

  set subjects(value: string[]) {
    this._subjects = value;
  }

  get tags(): string[] {
    return this._tags;
  }

  set tags(value: string[]) {
    this._tags = value;
  }

  get thesisInstitution(): string | null {
    return this._thesisInstitution;
  }

  set thesisInstitution(value: string | null) {
    this._thesisInstitution = value;
  }

  get thesisSupervisor(): string | null {
    return this._thesisSupervisor;
  }

  set thesisSupervisor(value: string | null) {
    this._thesisSupervisor = value;
  }

  get thesisType(): ThesisType | null {
    return this._thesisType;
  }

  set thesisType(value: ThesisType | null) {
    this._thesisType = value;
  }

  get title(): string | null {
    return this._title;
  }

  set title(value: string | null) {
    this._title = value;
  }

  get url(): string | null {
    return this._url;
  }

  set url(value: string | null) {
    this._url = value;
  }

  @computed
  get formValues(): MetodejDocument {
    return {
      abstract: this.abstract,
      ageCategories: this.ageCategories,
      authors: this.authors,
      coverPage: this.coverPage,
      documentFormat: this.documentFormat,
      documentId: this.documentId,
      documentType: this.documentType,
      doi: this.doi,
      edition: this.edition,
      fileName: this.fileName,
      hostEntity: this.hostEntity,
      isbn: this.isbn,
      issn: this.issn,
      languageCode: this.languageCode,
      medium: this.medium,
      pagesCount: this.pagesCount,
      publicationPlace: this.publicationPlace,
      publicationYear: this.publicationYear,
      publisher: this.publisher,
      sports: this.sports,
      subTitle: this.subTitle,
      subjects: this.subjects,
      tags: this.tags,
      thesisInstitution: this.thesisInstitution,
      thesisSupervisor: this.thesisSupervisor,
      thesisType: this.thesisType,
      title: this.title,
      url: this.url,
      isAnnotated: this.isAnnotated,
      isEditable: this.isEditable,
      isEdited: this.isEdited,
      isRead: this.isRead,
    };
  }

  private updateAttributeInternal<K extends keyof this>(
    key: K,
    value: this[K]
  ) {
    const adjustedValue =
      key === 'abstract'
        ? ({
            abstract: (value as Abstract)?.abstract,
            isGenerated: false,
            isTranslated: false,
          } as Abstract)
        : value;

    this[key] = adjustedValue as this[K];
  }

  async updateAttribute<K extends keyof this>(key: K, value: this[K]) {
    this.updateAttributeInternal(key, value);

    return api.updateDocument({
      documentId: this.documentId,
      [key]: this[key],
    });
  }

  async updateDocument(values: MetodejDocument) {
    const { documentId, ...valuesWithoutId } = values;

    Object.entries(valuesWithoutId).forEach(([key, value]) => {
      this.updateAttributeInternal(
        key as keyof this,
        value as this[keyof this]
      );
    });

    return api.updateDocument({
      ...this.formValues,
      documentId: this.documentId,
    });
  }
}
