import {
  Button,
  ButtonAppearance,
  PopoverProvider,
  Tippy,
} from '@yarmill/component-library';
import { Bin } from '@yarmill/icon-library';
import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import { useRouteMatch } from 'react-router-dom';
import { useDocumentLibraryStore } from '../hooks/use-document-library-store';
import { RemovePublishedDocumentPopover } from '../popovers/remove-published-document-popover';
import { DocumentStore } from '../stores/document-store';

export const RemovePublishedDocumentButton = observer(
  function RemovePublishedDocumentButton() {
    const intl = useIntl();
    const libraryStore = useDocumentLibraryStore();
    const label = 'metodej.upload.form.removeDocument';
    const {
      params: { id },
    } = useRouteMatch<{ readonly id: string }>();
    const document = id ? libraryStore.documentsById.get(Number(id)) : null;

    if (!(document instanceof DocumentStore)) {
      return null;
    }

    return (
      <PopoverProvider
        content={closePopover => (
          <RemovePublishedDocumentPopover closePopover={closePopover} />
        )}
      >
        <Tippy tooltipContent={label} noWrapper>
          <Button
            as="div"
            appearance={ButtonAppearance.Tertiary}
            icon={<Bin />}
            aria-label={intl.formatMessage({
              id: label,
            })}
          />
        </Tippy>
      </PopoverProvider>
    );
  }
);
