import {
  AxisConfig,
  AxisValue,
  CurveType,
  FloatingBarPosition,
  GlobalIntl,
  GridLinesConfig,
  LineStyle,
  TooltipValue,
  XAxisConfig,
  YAxisConfig,
} from '@yarmill/components';
import { GlobalLogger, lowerCaseFirstCharacter } from '@yarmill/utils';
import { action, computed, makeObservable, observable } from 'mobx';
import { ReactNode } from 'react';
import {
  BusinessFormatterOptions,
  ChartReportDataItem,
  ChartReportDataItemKey,
  ConfigOverride,
  DataDefinitionV2,
  LabelPosition,
  ReportItem,
} from '../types';
import {
  fillStringTemplate,
  formatValueByBusinessFormat,
  mapColor,
} from '../utils';

export class XyChartStore {
  private readonly _pageCode: string;
  private readonly _item: ReportItem;

  @observable
  private configOverride: ConfigOverride | undefined;

  constructor(item: ReportItem, pageCode: string) {
    this._item = item;
    this._pageCode = pageCode;
    makeObservable(this);
  }

  @computed
  public get axisX(): DataDefinitionV2 | undefined {
    return this.dataDefinition.find(def => def.Purpose === 'ValueForX');
  }

  @computed
  public get axisY(): DataDefinitionV2 | undefined {
    return this.dataDefinition.find(def => def.Purpose === 'ValueForY');
  }

  @computed
  public get dataColumns(): DataDefinitionV2[] {
    return this.dataDefinition.filter(def => def.Purpose === 'DataValue');
  }

  @computed
  public get dataColumnsByName(): Record<string, DataDefinitionV2> {
    return Object.fromEntries(this.dataColumns.map(def => [def.Name, def]));
  }

  @computed
  public get allColumnsByName(): Record<string, DataDefinitionV2> {
    return Object.fromEntries(this.dataDefinition.map(def => [def.Name, def]));
  }

  public getColorDef(
    ref: string | undefined | null
  ): DataDefinitionV2 | undefined {
    return (
      this.dataDefinition.find(
        def =>
          def.Purpose === 'ExtraValue' &&
          def.IsColor &&
          (!def.Ref || def.Ref === ref)
      ) || this.dataDefinition.find(def => def.Purpose === 'MultiLineColor')
    );
  }

  public getWidthDef(
    ref: string | undefined | null
  ): DataDefinitionV2 | undefined {
    return this.dataDefinition.find(
      def =>
        def.Purpose === 'ExtraValue' &&
        def.IsWidth &&
        (!def.Ref || def.Ref === ref)
    );
  }

  @computed
  public get category(): DataDefinitionV2 | undefined {
    return (
      this.dataDefinition.find(
        def => def.Purpose === 'ExtraValue' && def.IsCategory
      ) || this.dataDefinition.find(def => def.Purpose === 'MultiLineCategory')
    );
  }

  public getLineStyleDef(
    ref: string | undefined | null
  ): DataDefinitionV2 | undefined {
    return (
      this.dataDefinition.find(
        def =>
          def.Purpose === 'ExtraValue' &&
          def.IsLineStyle &&
          (!def.Ref || def.Ref === ref)
      ) ||
      this.dataDefinition.find(
        def =>
          def.Purpose === 'MultiLineLineStyle' && (!def.Ref || def.Ref === ref)
      )
    );
  }

  public getLabelColorDef(
    ref: string | undefined | null
  ): DataDefinitionV2 | undefined {
    return (
      this.dataDefinition.find(
        def =>
          def.Purpose === 'ExtraValue' &&
          def.IsLabelColor &&
          (!def.Ref || def.Ref === ref)
      ) ||
      this.dataDefinition.find(
        def =>
          def.Purpose === 'MultiLineLabelColor' && (!def.Ref || def.Ref === ref)
      )
    );
  }

  public getLineOrderDef(
    ref: string | undefined | null
  ): DataDefinitionV2 | undefined {
    return this.dataDefinition.find(
      def =>
        def.Purpose === 'ExtraValue' &&
        def.IsLineOrder &&
        (!def.Ref || def.Ref === ref)
    );
  }

  public getMarkerSizeDef(
    ref: string | undefined | null
  ): DataDefinitionV2 | undefined {
    return this.dataDefinition.find(
      def =>
        def.Purpose === 'ExtraValue' &&
        def.IsMarkerSize &&
        (!def.Ref || def.Ref === ref)
    );
  }

  public getMarkerColorDef(
    ref: string | undefined | null
  ): DataDefinitionV2 | undefined {
    return this.dataDefinition.find(
      def =>
        def.Purpose === 'ExtraValue' &&
        def.IsMarkerColor &&
        (!def.Ref || def.Ref === ref)
    );
  }

  public getMarkerStrokeWidthDef(
    ref: string | undefined | null
  ): DataDefinitionV2 | undefined {
    return this.dataDefinition.find(
      def =>
        def.Purpose === 'ExtraValue' &&
        def.IsMarkerStrokeWidth &&
        (!def.Ref || def.Ref === ref)
    );
  }

  public getMarkerStrokeColorDef(
    ref: string | undefined | null
  ): DataDefinitionV2 | undefined {
    return this.dataDefinition.find(
      def =>
        def.Purpose === 'ExtraValue' &&
        def.IsMarkerStrokeColor &&
        (!def.Ref || def.Ref === ref)
    );
  }

  public getStrokeWidthDef(
    ref: string | undefined | null
  ): DataDefinitionV2 | undefined {
    return this.dataDefinition.find(
      def =>
        def.Purpose === 'ExtraValue' &&
        def.IsStrokeWidth &&
        (!def.Ref || def.Ref === ref)
    );
  }

  public getShowLabelsDef(
    ref: string | undefined | null
  ): DataDefinitionV2 | undefined {
    return this.dataDefinition.find(
      def =>
        def.Purpose === 'ExtraValue' &&
        def.IsShowLabels &&
        (!def.Ref || def.Ref === ref)
    );
  }

  public getOpacityDef(
    ref: string | undefined | null
  ): DataDefinitionV2 | undefined {
    return this.dataDefinition.find(
      def =>
        def.Purpose === 'ExtraValue' &&
        (def.IsStrokeOpacity || def.IsOpacity) &&
        (!def.Ref || def.Ref === ref)
    );
  }

  public getLabelAngleDef(
    ref: string | undefined | null
  ): DataDefinitionV2 | undefined {
    return this.dataDefinition.find(
      def =>
        def.Purpose === 'ExtraValue' &&
        def.IsLabelAngle &&
        (!def.Ref || def.Ref === ref)
    );
  }

  public getBarPositionDef(
    ref: string | undefined | null
  ): DataDefinitionV2 | undefined {
    return this.dataDefinition.find(
      def =>
        def.Purpose === 'ExtraValue' &&
        def.IsBarPosition &&
        (!def.Ref || def.Ref === ref)
    );
  }

  public getStrokeDasharrayDef(
    ref: string | undefined | null
  ): DataDefinitionV2 | undefined {
    return this.dataDefinition.find(
      def =>
        def.Purpose === 'ExtraValue' &&
        def.IsStrokeDasharray &&
        (!def.Ref || def.Ref === ref)
    );
  }

  public getCurveTypeDef(
    ref: string | undefined | null
  ): DataDefinitionV2 | undefined {
    return this.dataDefinition.find(
      def =>
        def.Purpose === 'ExtraValue' &&
        def.IsCurveType &&
        (!def.Ref || def.Ref === ref)
    );
  }

  @computed
  public get tooltipLabel(): DataDefinitionV2 | undefined {
    return this.dataDefinition.find(
      def => def.Purpose === 'ExtraValue' && def.IsTooltipLabel
    );
  }

  public getHideInTooltipDef(
    ref: string | undefined | null
  ): DataDefinitionV2 | undefined {
    return this.dataDefinition.find(
      def =>
        def.Purpose === 'ExtraValue' &&
        def.IsHideInTooltip &&
        (!def.Ref || def.Ref === ref)
    );
  }

  @computed
  public get axisXLabel(): DataDefinitionV2 | undefined {
    return this.dataDefinition.find(
      def => def.Purpose === 'ExtraValue' && def.IsAxisXLabel
    );
  }

  @computed
  public get axisYLabel(): DataDefinitionV2 | undefined {
    return this.dataDefinition.find(
      def => def.Purpose === 'ExtraValue' && def.IsAxisYLabel
    );
  }

  public getHighlightColorDef(
    ref: string | undefined | null
  ): DataDefinitionV2 | undefined {
    return this.dataDefinition.find(
      def =>
        def.Purpose === 'ExtraValue' &&
        def.IsHighlightColor &&
        (!def.Ref || def.Ref === ref)
    );
  }

  public readonly getShowLabels = (
    item: ChartReportDataItem,
    key?: string
  ): boolean => {
    const showLabelsKey = this.getShowLabelsDef(key)?.Name;
    const def = key ? this.dataColumnsByName[key] : undefined;

    return showLabelsKey
      ? Boolean(item[showLabelsKey])
      : (def?.ShowLabels ?? Boolean(this.params?.ShowLabels));
  };

  @computed
  public get labelPosition(): LabelPosition {
    return this.params?.LabelPosition || 'outside';
  }

  @computed
  public get showAxisXLabels(): boolean {
    return Boolean(this.params?.ShowAxisXLabels);
  }

  @computed
  public get showAxisXLabelsInTooltip(): boolean {
    return Boolean(this.params?.ShowAxisXLabelsInTooltip);
  }

  @computed
  public get tooltipTableLayout(): string | undefined {
    return this.axisX?.TooltipLayout || undefined;
  }

  @computed
  public get showTooltipTableColumnLabels(): boolean {
    return Boolean(this.axisX?.ShowTooltipColumnLabels);
  }

  @computed
  public get showAxisYLabels(): boolean {
    return Boolean(this.params?.ShowAxisYLabels);
  }

  @computed
  public get showAxisYLabelsInTooltip(): boolean {
    return Boolean(this.params?.ShowAxisYLabelsInTooltip);
  }

  @computed
  public get sharedGuidingLines(): boolean {
    return Boolean(this.params?.SharedGuidingLines);
  }

  @computed
  public get height(): number | undefined {
    return this._item.Params?.Height;
  }

  public readonly getMarkerSize = (
    item: ChartReportDataItem,
    key: string
  ): number | undefined => {
    const markerSizeKey = this.getMarkerSizeDef(key)?.Name;
    const def = key ? this.dataColumnsByName[key] : undefined;

    return markerSizeKey ? Number(item[markerSizeKey]) : def?.MarkerSize;
  };

  public readonly getMarkerColor = (
    item: ChartReportDataItem,
    key: string
  ): string | undefined => {
    const markerColorKey = this.getMarkerColorDef(key)?.Name;
    const def = key ? this.dataColumnsByName[key] : undefined;

    const color = markerColorKey
      ? String(item[markerColorKey])
      : def?.MarkerColor;

    return color ? mapColor(color) : undefined;
  };

  public readonly getMarkerStrokeWidth = (
    item: ChartReportDataItem,
    def?: DataDefinitionV2
  ): number | string | undefined => {
    const markerStrokeWidthKey = this.getMarkerStrokeWidthDef(def?.Name)?.Name;

    return markerStrokeWidthKey
      ? (item[markerStrokeWidthKey] as number)
      : (def?.MarkerStrokeWidth ?? undefined);
  };

  public readonly getMarkerStrokeColor = (
    item: ChartReportDataItem,
    def?: DataDefinitionV2
  ): string | undefined => {
    const markerStrokeColorKey = this.getMarkerStrokeColorDef(def?.Name)?.Name;

    return markerStrokeColorKey
      ? (item[markerStrokeColorKey] as string)
      : (def?.MarkerStrokeColor ?? undefined);
  };

  public readonly getStrokeWidth = (
    item: ChartReportDataItem,
    def?: DataDefinitionV2
  ): number | undefined => {
    const strokeWidthKey = this.getStrokeWidthDef(def?.Name)?.Name;

    return strokeWidthKey ? (item[strokeWidthKey] as number) : def?.StrokeWidth;
  };

  public readonly getOpacity = (
    item: ChartReportDataItem,
    key?: string
  ): number | undefined => {
    const opacityKey = this.getOpacityDef(key)?.Name;
    const def = key ? this.dataColumnsByName[key] : undefined;

    return opacityKey
      ? (item[opacityKey] as number)
      : (def?.StrokeOpacity ?? def?.Opacity);
  };

  public readonly getLabelAngle = (
    item: ChartReportDataItem,
    key?: string
  ): number => {
    const labelAngleKey = this.getLabelAngleDef(key)?.Name;
    const def = key ? this.dataColumnsByName[key] : undefined;

    return (
      (labelAngleKey ? (item[labelAngleKey] as number) : def?.LabelAngle) ?? 0
    );
  };

  public readonly getBarPosition = (
    item: ChartReportDataItem,
    key?: string
  ): FloatingBarPosition => {
    const barPositionKey = this.getBarPositionDef(key)?.Name;
    const def = key ? this.dataColumnsByName[key] : undefined;

    return (
      (barPositionKey
        ? (item[barPositionKey] as FloatingBarPosition)
        : def?.BarPosition) ?? 'center'
    );
  };

  public readonly getStrokeDasharray = (
    item: ChartReportDataItem,
    def?: DataDefinitionV2
  ): number | undefined => {
    const strokeDasharrayKey = this.getStrokeDasharrayDef(def?.Name)?.Name;

    return strokeDasharrayKey
      ? (item[strokeDasharrayKey] as number)
      : def?.StrokeDasharray;
  };

  public readonly getCurveType = (
    item: ChartReportDataItem,
    key: string
  ): CurveType => {
    const curveTypeKey = this.getCurveTypeDef(key)?.Name;
    const line = this.dataColumnsByName[key];
    let curveType = line.CurveType;

    if (curveTypeKey) {
      curveType = String(item[curveTypeKey]) as CurveType | undefined;
    }

    return curveType || 'monotoneX';
  };

  public readonly getColor = (
    item: ChartReportDataItem,
    key: string
  ): string => {
    const colorKey = this.getColorDef(key)?.Name;
    const def = this.dataColumnsByName[key];
    let color = def?.Color;

    if (colorKey) {
      color = String(item[colorKey]);
    }

    return color ? mapColor(color) : '';
  };

  public readonly getWidth = (
    item: ChartReportDataItem,
    key: string
  ): number => {
    const widthKey = this.getWidthDef(key)?.Name;
    const def = this.dataColumnsByName[key];
    let width = def?.Width;

    if (widthKey) {
      width = item[widthKey];
    }

    return width !== undefined ? Number(width) : 1;
  };

  public readonly getLabelColor = (
    item: ChartReportDataItem,
    key: string
  ): string => {
    const labelColorKey = this.getLabelColorDef(key)?.Name;
    const def = this.dataColumnsByName[key];
    let color = def?.LabelColor;

    if (labelColorKey) {
      color = String(item[labelColorKey]);
    }

    return mapColor(color);
  };

  public readonly getLineOrder = (item: ChartReportDataItem): number => {
    const lineOrderKey = this.getLineOrderDef(null)?.Name;

    if (lineOrderKey) {
      return Number(item[lineOrderKey]);
    }

    return 0;
  };

  public readonly getLineStyle = (
    item: ChartReportDataItem,
    key: string
  ): LineStyle => {
    const lineStyleKey = this.getLineStyleDef(key)?.Name;
    const def = this.dataColumnsByName[key];
    let lineStyle = def?.LineStyle;

    if (lineStyleKey) {
      lineStyle = String(item[lineStyleKey]) as LineStyle | undefined;
    }

    return lineStyle || 'default';
  };

  public readonly getHighlightColor = (
    item: ChartReportDataItem,
    key: string
  ): string | null => {
    const highlightColorKey = this.getHighlightColorDef(key)?.Name;
    const def = this.dataColumnsByName[key];
    let color = def?.HighlightColor;

    if (highlightColorKey) {
      color = String(item[highlightColorKey]);
    }

    return color ? mapColor(color) : null;
  };

  public readonly getXValue = <
    Type extends ChartReportDataItem[ChartReportDataItemKey],
  >(
    item: ChartReportDataItem
  ): Type => {
    const key = this.axisX?.Name ?? '';
    if (!key) {
      GlobalLogger.error(
        'Reporting configuration error',
        `Page: ${this._pageCode}`,
        `Report: ${this._item.Code}`,
        'Missing value for x key'
      );
    }

    return item[key] as Type;
  };

  public readonly getYValue = <
    Type extends ChartReportDataItem[ChartReportDataItemKey],
  >(
    item: ChartReportDataItem
  ): Type => {
    const key = this.axisY?.Name ?? '';
    if (!key) {
      GlobalLogger.error(
        'Reporting configuration error',
        `Page: ${this._pageCode}`,
        `Report: ${this._item.Code}`,
        'Missing value for y key'
      );
    }

    return item[key] as Type;
  };

  @computed
  public get baseConfig() {
    return {
      getXValue: this.getXValue,
      getYValue: this.getYValue,
      getColor: this.getColor,
      getLabelColor: this.getLabelColor,
      getMarkerSize: this.getMarkerSize,
      labelPosition: this.labelPosition,
      getCurveType: this.getCurveType,
      axisConfigs: this.axisConfigs,
    };
  }

  @computed
  public get tooltipTableLayoutConfig() {
    const tableLayout = this.tooltipTableLayout;
    const showColumnLabels = this.showTooltipTableColumnLabels;

    return {
      tableLayout,
      showColumnLabels,
      tableColumnsLabels:
        tableLayout && showColumnLabels
          ? Object.fromEntries(
              tableLayout.split(' ').map(key => [
                key,
                GlobalIntl.formatMessage({
                  id:
                    this.allColumnsByName[key]?.Label ||
                    this.allColumnsByName[key]?.Name ||
                    key,
                }),
              ])
            )
          : undefined,
    };
  }

  public mapTooltipValues(
    item: ChartReportDataItem,
    axis: 'x' | 'y'
  ): TooltipValue[] {
    const axisDefinition = axis === 'x' ? this.axisX : this.axisY;
    const tooltipItemLabel = axisDefinition?.TooltipItemLabel;
    const tooltipItemValue = axisDefinition?.TooltipItemValue;
    const tableColumns = axisDefinition?.TooltipLayout?.split(' ');

    const replacementValues =
      tooltipItemLabel || tooltipItemValue
        ? this.formatItemByDataTypes(item)
        : {};

    const getReferencedLabelValue = (columnName: string): string => {
      const def = this.allColumnsByName[columnName];
      if (!def) {
        return '';
      }
      const value = item[def.Name];

      return formatValueByBusinessFormat(
        value,
        def.BusinessFormat,
        def.Format,
        {
          forceString: true,
        }
      ) as string;
    };

    return this.dataColumns
      .map(def => ({
        label: def.TooltipLabelRef
          ? getReferencedLabelValue(def.TooltipLabelRef)
          : GlobalIntl.formatMessage(
              {
                id: tooltipItemLabel
                  ? tooltipItemLabel
                  : def.Label ||
                    `reporting.${lowerCaseFirstCharacter(
                      this._pageCode
                    )}.${lowerCaseFirstCharacter(
                      this._item.Code
                    )}.${lowerCaseFirstCharacter(def.Name)}`,
              },
              replacementValues
            ),
        value: tooltipItemValue
          ? fillStringTemplate(tooltipItemValue, replacementValues)
          : formatValueByBusinessFormat(
              item[def.Name],
              def.BusinessFormat,
              def.Format
            ),
        color: mapColor(this.getColor(item, def.Name)),
        originalValue: item[def.Name],
        key: def.Name,
        tableValues: tableColumns?.length
          ? this.mapTooltipTableLayoutValues(item, tableColumns)
          : undefined,
        hidden: Boolean(
          def.HideInTooltip ||
            (this.getHideInTooltipDef(def.Name) &&
              item[this.getHideInTooltipDef(def.Name)!.Name])
        ),
      }))
      .sort((a, b) =>
        this.params?.SortBy === 'category'
          ? 0
          : Number(b.originalValue || 0) - Number(a.originalValue || 0)
      );
  }

  public mapMultiLineTooltipValues(
    items: ChartReportDataItem[],
    axis: 'x' | 'y'
  ): TooltipValue[] {
    const dataColumn = this.dataColumns[0];
    const axisDefinition = axis === 'x' ? this.axisX : this.axisY;

    if (!dataColumn || dataColumn.HideInTooltip || !axisDefinition) {
      return [];
    }

    const tooltipItemLabel = axisDefinition?.TooltipItemLabel;
    const tooltipItemValue = axisDefinition?.TooltipItemValue;
    const tableColumns = axisDefinition?.TooltipLayout?.split(' ');

    return items
      .map(item => {
        const replacementValues =
          tooltipItemLabel || tooltipItemValue
            ? this.formatItemByDataTypes(item)
            : {};

        return {
          label: tooltipItemLabel
            ? GlobalIntl.formatMessage(
                {
                  id: tooltipItemLabel,
                },
                replacementValues
              )
            : String(item[this.category?.Name ?? '']),
          value: tooltipItemValue
            ? fillStringTemplate(tooltipItemValue, replacementValues)
            : formatValueByBusinessFormat(
                item[dataColumn.Name],
                dataColumn.BusinessFormat,
                dataColumn.Format
              ),
          color: mapColor(this.getColor(item, dataColumn.Name)),
          originalValue: item[dataColumn.Name],
          axisValue: item[axisDefinition.Name],
          tableValues: tableColumns?.length
            ? this.mapTooltipTableLayoutValues(item, tableColumns)
            : undefined,
          hidden: Boolean(
            dataColumn.HideInTooltip ||
              (this.getHideInTooltipDef(dataColumn.Name) &&
                item[this.getHideInTooltipDef(dataColumn.Name)!.Name])
          ),
        };
      })
      .sort(
        (a, b) => Number(b.originalValue || 0) - Number(a.originalValue || 0)
      );
  }

  public formatAxisValue(
    tickValue: AxisValue,
    axis: 'x' | 'y',
    options?: BusinessFormatterOptions,
    getItem?: (
      key: string,
      tickValue: AxisValue
    ) => ChartReportDataItem | undefined
  ): string {
    const def = axis === 'x' ? this.axisX : this.axisY;
    const customLabelDef = axis === 'x' ? this.axisXLabel : this.axisYLabel;

    if (!def) {
      return String(tickValue);
    }

    if ((def.TranslateValue || customLabelDef) && getItem) {
      let values: Record<string, string> = {};
      const item = getItem(def.Name, tickValue);
      if (item) {
        values = this.formatItemByDataTypes(item);
      }

      const axisLabelDef = (customLabelDef ?? def) as DataDefinitionV2;
      if (axisLabelDef.TranslateValue) {
        if (customLabelDef && !values[customLabelDef.Name]) {
          return '';
        }

        return GlobalIntl.formatMessage(
          { id: values[axisLabelDef.Name] || String(tickValue) },
          values
        );
      } else {
        return values[axisLabelDef.Name] ?? '';
      }
    }

    return formatValueByBusinessFormat(
      tickValue as string | number,
      def.BusinessFormat,
      def.Format,
      options
    ) as string;
  }

  public formatTooltipLabel(
    tickValue: AxisValue,
    axis: 'x' | 'y',
    item?: ChartReportDataItem
  ): string {
    const axisDef = axis === 'x' ? this.axisX : this.axisY;
    const customAxisLabelDef = axis === 'x' ? this.axisXLabel : this.axisYLabel;
    const tooltipLabelDefinition = this.tooltipLabel;

    if (!axisDef) {
      return String(tickValue);
    }

    if (tooltipLabelDefinition?.TooltipLabel || axisDef?.TooltipLabel) {
      const replacementValues = this.formatItemByDataTypes(item ?? {});

      return GlobalIntl.formatMessage(
        { id: tooltipLabelDefinition?.TooltipLabel || axisDef.TooltipLabel },
        replacementValues
      );
    }

    const value =
      tooltipLabelDefinition && item
        ? item[tooltipLabelDefinition.Name]
        : tickValue;

    if (axisDef.TranslateValue || customAxisLabelDef) {
      const replacementValues = this.formatItemByDataTypes(item ?? {});

      if (customAxisLabelDef && !customAxisLabelDef?.TranslateValue) {
        return replacementValues[customAxisLabelDef.Name] ?? '';
      }

      if (customAxisLabelDef && !replacementValues[customAxisLabelDef.Name]) {
        return '';
      }

      const value = customAxisLabelDef
        ? replacementValues[customAxisLabelDef.Name]
        : String(tickValue);

      return GlobalIntl.formatMessage({ id: value }, replacementValues);
    }

    return formatValueByBusinessFormat(
      value as number | string,
      tooltipLabelDefinition?.BusinessFormat ??
        axisDef.TooltipLabelBusinessFormat ??
        axisDef.BusinessFormat,
      tooltipLabelDefinition?.Format ??
        axisDef.TooltipLabelFormat ??
        axisDef.Format,
      { forceString: true }
    ) as string;
  }

  public readonly formatAxisXTick = (
    tickValue: AxisValue,
    getItem?: (
      key: string,
      tickValue: AxisValue
    ) => ChartReportDataItem | undefined
  ): string => {
    return this.formatAxisValue(
      tickValue,
      'x',
      {
        forceString: true,
      },
      getItem
    ) as string;
  };

  public readonly formatAxisYTick = (
    tickValue: AxisValue,
    getItem?: (
      key: string,
      tickValue: AxisValue
    ) => ChartReportDataItem | undefined
  ): string => {
    return this.formatAxisValue(
      tickValue,
      'y',
      {
        forceString: true,
      },
      getItem
    ) as string;
  };

  @computed
  public get axisConfigs(): AxisConfig<AxisValue, AxisValue>[] {
    const isHorizontal =
      this._item.Kind === 'HorizontalClusteredColumnChart' ||
      this._item.Kind === 'HorizontalStackedColumnChart';

    const x: XAxisConfig<AxisValue> = {
      axis: 'x',
      key: this.axisX?.Name ?? '',
      tickAngle: this.axisX?.AxisTickAngle ?? 0,
      numberOfTicks: this.axisX?.AxisTicksCount,
      formatTick: this.formatAxisXTick,
      showTickLabels:
        this.axisX?.ShowLabels ?? this.params?.ShowAxisXLabels ?? !isHorizontal,
      hideAxisLine: this.axisX?.HideAxisLine,
      showZeroLine: this.axisX?.ShowZeroLine,
      type: this.axisXType,
      translateValues: this.axisX?.TranslateValue,
    };
    const y: YAxisConfig<AxisValue> = {
      axis: 'y',
      key: this.axisY?.Name ?? '',
      tickAngle: this.axisY?.AxisTickAngle ?? 0,
      numberOfTicks: this.axisY?.AxisTicksCount,
      formatTick: this.formatAxisYTick,
      showTickLabels:
        this.axisY?.ShowLabels ?? this.params?.ShowAxisYLabels ?? isHorizontal,
      hideAxisLine: this.axisY?.HideAxisLine,
      showZeroLine: this.axisY?.ShowZeroLine,
      type: this.axisYType,
      translateValues: this.axisY?.TranslateValue,
    };

    return [x, y];
  }

  @computed
  public get gridLinesConfig(): GridLinesConfig | undefined {
    return this.params?.GridLinesConfig;
  }

  @action
  public setConfigOverride(config: ConfigOverride | undefined) {
    this.configOverride = config;
  }

  @computed
  public get noDataMessage(): string | null | undefined {
    return this.params?.NoDataMessage;
  }

  private formatItemByDataTypes(
    item: ChartReportDataItem
  ): Record<string, string> {
    const entries = Object.entries(item).map(([key, value]) => {
      const def = this.allColumnsByName[key];

      return [
        key,
        def
          ? formatValueByBusinessFormat(value, def.BusinessFormat, def.Format, {
              forceString: true,
            })
          : value,
      ];
    });

    return Object.fromEntries(entries);
  }

  private mapTooltipTableLayoutValues(
    item: ChartReportDataItem,
    tableColumns: string[]
  ): Record<string, ReactNode> {
    return Object.fromEntries(
      tableColumns.map(columnKey => {
        const def = this.allColumnsByName[columnKey];
        if (!def) {
          return [columnKey, ''];
        }
        const val = item[def.Name];

        return [
          columnKey,
          formatValueByBusinessFormat(val, def.BusinessFormat, def.Format),
        ];
      })
    );
  }

  @computed
  private get dataDefinition(): DataDefinitionV2[] {
    return this.configOverride?.DataDefinition ?? this._item.DataDefinition;
  }

  @computed
  private get params() {
    return this.configOverride?.Params ?? this._item.Params;
  }

  @computed
  private get axisXType() {
    return this._item.Kind === 'ContinuousChart' ||
      this._item.Kind === 'TimeChart' ||
      this._item.Kind === 'HorizontalClusteredColumnChart' ||
      this._item.Kind === 'HorizontalStackedColumnChart'
      ? 'continuous'
      : 'categorical';
  }

  @computed
  private get axisYType() {
    return this._item.Kind === 'HorizontalClusteredColumnChart' ||
      this._item.Kind === 'HorizontalStackedColumnChart'
      ? 'categorical'
      : 'continuous';
  }
}
