import { Export } from '@yarmill/components';
import {
  ExportAttendanceToXlsxRequestParams,
  ExportItem,
} from '@yarmill/types';
import {
  getWeekEndString,
  getWeekStartString,
  useCurrentUserStore,
  useLocale,
  useRootStore,
} from '@yarmill/utils';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { ReactElement, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useAttendanceStore } from './attendance-store-context';

export const ExportAttendanceButton = observer(
  function ExportAttendanceButton(): ReactElement | null {
    const exportService = useRootStore().exportService;
    const attendanceStore = useAttendanceStore();
    const language = useLocale();
    const intl = useIntl();
    const currentUser = useCurrentUserStore();

    const exportToXlsx = useCallback(async () => {
      const week = attendanceStore.week;
      const group = attendanceStore.group;

      if (!week || !group) {
        return;
      }

      const startDate = getWeekStartString(week);
      const endDate = getWeekEndString(week);
      const name = currentUser.isAthlete ? currentUser.displayName : group.name;

      const fileName = `${intl.formatMessage({
        id: `header.navigation.attendance`,
      })} - ${name} - ${moment(startDate).format('YYYY-MM')}`;

      const params: ExportAttendanceToXlsxRequestParams = {
        language,
        startDate,
        endDate,
        userGroupId: group.id,
      };

      return exportService.exportAttendanceToXlsx(params, fileName);
    }, [
      attendanceStore.week,
      attendanceStore.group,
      intl,
      language,
      exportService,
      currentUser.isAthlete,
      currentUser.displayName,
    ]);

    const exportItems = useMemo(
      (): ExportItem[] => [
        {
          format: 'xlsx',
          doExport: exportToXlsx,
          label: intl.formatMessage({ id: 'export.attendance.title.xlsx' }),
          description: intl.formatMessage({
            id: 'export.attendance.description.xlsx',
          }),
        },
      ],
      [exportToXlsx, intl]
    );

    return <Export exportItems={exportItems} />;
  }
);
