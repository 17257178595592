import { Author } from './author';

export const DOCUMENT_FORMATS = ['scan', 'digital'] as const;
export const DOCUMENT_TYPES = [
  'book',
  'academicThesis',
  'researchPaperArticle',
  'methodologicalGuidelines',
  'lecture',
  'webPost',
  'other',
] as const;
export const THESIS_TYPES = [
  'bachelorsThesis',
  'mastersThesis',
  'doctoralDissertation',
  'rigorosumThesis',
  'habilitationThesis',
] as const;
export type DocumentFormat = (typeof DOCUMENT_FORMATS)[number];
export type DocumentType = (typeof DOCUMENT_TYPES)[number];
export type ThesisType = (typeof THESIS_TYPES)[number];

export interface Abstract {
  abstract: string;
  isGenerated: boolean;
  isTranslated: boolean;
}
export interface CoverPage {
  coverPageUrl: string;
  useOriginalCover: boolean;
}

export interface MetodejDocument {
  documentId: number;
  isAnnotated: boolean;
  isEditable: boolean;
  isEdited: boolean;
  isRead: boolean;
  title: string | null;
  subTitle: string | null;
  url: string | null;
  pagesCount: number;
  languageCode: string | null;
  coverPage: CoverPage;
  documentFormat: DocumentFormat | null;
  documentType: DocumentType | null;
  publicationYear: number;
  publicationPlace: string | null;
  publisher: string | null;
  isbn: string | null;
  issn: string | null;
  doi: string | null;
  edition: string | null;
  hostEntity: string | null;
  thesisType: ThesisType | null;
  thesisInstitution: string | null;
  thesisSupervisor: string | null;
  medium: string | null;
  fileName: string | null;
  authors: Author[];
  abstract: Abstract | null;
  sports: string[];
  subjects: string[];
  ageCategories: string[];
  tags: string[];
}
