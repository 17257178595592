import { Tippy } from '@yarmill/components';
import { InputHTMLAttributes, ReactElement, forwardRef } from 'react';
import { Badge, BadgeAppearance } from './badge';
import { FormInputError } from './form-input-error';
import { FormInputHint } from './form-input-hint';
import { FormInputLabel } from './form-input-label';
import { FormInputLayout } from './form-input-layout';
import { getTextAppearanceStyles } from './text';
import { ThemeDefinition, css, styled } from './theme-provider';

export enum TextInputAppearance {
  Neutral = 'neutral',
  Tinted = 'tinted',
  TintedDark = 'tinted-dark',
  Error = 'error',
  Success = 'success',
}

function getAppearanceColors(
  appearance: TextInputAppearance,
  theme: ThemeDefinition
) {
  if (theme.dark) {
    switch (appearance) {
      case TextInputAppearance.Neutral:
        return {
          color: theme.color.neutral_neutral_2,
          borderColor: theme.color.neutral_neutral_darker,
          backgroundColor: theme.color.neutral_black,
          hoverBorderColor: theme.color.neutral_neutral,
          hoverBackgroundColor: theme.color.neutral_black,
          focusBorderColor: theme.color.brand_yarmill,
          focusBackgroundColor: theme.color.neutral_black,
        };
      case TextInputAppearance.Tinted:
        return {
          color: theme.color.brand_yarmill_56,
          borderColor: theme.color.neutral_black,
          backgroundColor: theme.color.neutral_black,
          hoverBorderColor: theme.color.neutral_neutral_darker,
          hoverBackgroundColor: theme.color.neutral_neutral_darker,
          focusBorderColor: theme.color.brand_yarmill_56,
          focusBackgroundColor: theme.color.neutral_black,
        };
      case TextInputAppearance.Error:
        return {
          color: theme.color.neutral_neutral_2,
          borderColor: theme.color.other_red,
          backgroundColor: theme.color.neutral_black,
          hoverBorderColor: theme.color.other_red,
          hoverBackgroundColor: theme.color.neutral_black,
          focusBorderColor: theme.color.other_red,
          focusBackgroundColor: theme.color.neutral_black,
        };
      case TextInputAppearance.Success:
        return {
          color: theme.color.neutral_neutral_2,
          borderColor: theme.color.other_green,
          backgroundColor: theme.color.neutral_black,
          hoverBorderColor: theme.color.other_green,
          hoverBackgroundColor: theme.color.neutral_black,
          focusBorderColor: theme.color.other_green,
          focusBackgroundColor: theme.color.neutral_black,
        };
    }
  } else {
    switch (appearance) {
      case TextInputAppearance.Neutral:
        return {
          color: theme.color.neutral_neutral_dark,
          borderColor: theme.color.background_background_03,
          backgroundColor: theme.color.neutral_white,
          hoverBorderColor: theme.color.background_background_04,
          hoverBackgroundColor: theme.color.neutral_white,
          focusBorderColor: theme.color.brand_yarmill,
          focusBackgroundColor: theme.color.neutral_white,
        };
      case TextInputAppearance.Tinted:
        return {
          color: theme.color.neutral_neutral_dark,
          borderColor: theme.color.background_background_02,
          backgroundColor: theme.color.background_background_02,
          hoverBorderColor: theme.color.background_background_03,
          hoverBackgroundColor: theme.color.background_background_03,
          focusBorderColor: theme.color.brand_yarmill,
          focusBackgroundColor: theme.color.neutral_white,
        };
      case TextInputAppearance.TintedDark:
        return {
          color: theme.color.neutral_neutral_dark,
          borderColor: theme.color.background_background_05,
          backgroundColor: theme.color.background_background_05,
          hoverBorderColor: theme.color.background_background_05,
          hoverBackgroundColor: theme.color.background_background_05,
          focusBorderColor: theme.color.brand_yarmill,
          focusBackgroundColor: theme.color.background_background_04,
        };
      case TextInputAppearance.Error:
        return {
          color: theme.color.neutral_neutral_dark,
          borderColor: theme.color.other_red,
          backgroundColor: theme.color.other_red_8,
          hoverBorderColor: theme.color.other_red,
          hoverBackgroundColor: theme.color.other_red_16,
          focusBorderColor: theme.color.brand_yarmill,
          focusBackgroundColor: theme.color.neutral_white,
        };
      case TextInputAppearance.Success:
        return {
          color: theme.color.neutral_neutral_dark,
          borderColor: theme.color.other_green,
          backgroundColor: theme.color.other_green_8,
          hoverBorderColor: theme.color.other_green,
          hoverBackgroundColor: theme.color.other_green_16,
          focusBorderColor: theme.color.brand_yarmill,
          focusBackgroundColor: theme.color.neutral_white,
        };
    }
  }
}

interface TextInputProps extends InputHTMLAttributes<HTMLInputElement> {
  readonly value?: string;
  readonly defaultValue?: string;
  readonly label?: string;
  readonly labelHint?: string;
  readonly align?: 'left' | 'right';
  readonly icon?: ReactElement;
  readonly name: string;
  readonly appearance?: TextInputAppearance;
  readonly id: string;
  readonly hint?: string;
  readonly error?: string;
}

interface StyledInputProps {
  readonly $appearance: TextInputAppearance;
  readonly align: 'left' | 'right';
  readonly hasIcon: boolean;
}

export const StyledTextInput = styled.input<StyledInputProps>`
  width: 100%;
  flex-shrink: 0;
  transition: background-color 200ms ease, border 200ms ease;


  ${({ theme, $appearance, align, hasIcon }) => {
    const appearanceColors = getAppearanceColors($appearance, theme);

    return css`

      height: ${theme.size.x5};
      padding: ${theme.size.x1};
      padding-left: ${hasIcon ? theme.size.x4 : theme.size.x1};
      font-family: ${theme.text.font.default};
      ${getTextAppearanceStyles('_12M')};
      text-align: ${align};
      border-radius: ${theme.borderRadius.x075};
      color: ${appearanceColors?.color};
      background: ${appearanceColors?.backgroundColor};
      border: ${theme.size.x0125} solid ${appearanceColors?.borderColor};
      caret-color: ${theme.color.brand_yarmill};

      &:hover {
        border-color: ${appearanceColors?.hoverBorderColor};
        background: ${appearanceColors?.hoverBackgroundColor};
      }

      &:focus-visible {
        outline: none;
        border-color: ${appearanceColors?.focusBorderColor};
        background: ${appearanceColors?.focusBackgroundColor};
      }

      &::placeholder {
        color: ${theme.dark ? theme.color.neutral_neutral : theme.color.neutral_neutral_64};
        z-index: 1;
        padding-left: ${theme.size.x025};
      }

      @media(min-width: 768px) {
        height: ${theme.size.x4};
      }
    `;
  }}
`;

export const IconWrapper = styled.div<{
  readonly $appearance: TextInputAppearance;
}>`
  position: absolute;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  left: ${({ theme }) => theme.size.x1};

   ${({ theme }) => {
     return css`
       color: ${theme.color.neutral_neutral};
       ${StyledTextInput}:not(:focus):placeholder-shown + &, ${StyledTextInput}:empty:not(:focus) + & {
         color: ${theme.color.neutral_neutral};
       }
     `;
   }};

  svg {
    width:  ${({ theme }) => theme.size.x2};
    height:  ${({ theme }) => theme.size.x2};
  }
`;

export const TextInputWrapper = styled.div`
  position: relative;
`;

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  function TextInput(
    {
      label,
      labelHint,
      align = 'left',
      appearance = TextInputAppearance.Neutral,
      icon,
      hint,
      error,
      ...textInputProps
    },
    ref
  ): ReactElement {
    return (
      <FormInputLayout>
        {label && (
          <FormInputLabel htmlFor={textInputProps.id} align={align}>
            {label}
            {labelHint && (
              <Tippy tooltipContent={labelHint} translateValue={false}>
                <Badge size={12} appearance={BadgeAppearance.hint} />
              </Tippy>
            )}
          </FormInputLabel>
        )}
        <TextInputWrapper>
          <StyledTextInput
            {...textInputProps}
            $appearance={appearance}
            align={align}
            ref={ref}
            hasIcon={Boolean(icon)}
            value={textInputProps.value === null ? '' : textInputProps.value}
          />
          {icon && <IconWrapper $appearance={appearance}>{icon}</IconWrapper>}
        </TextInputWrapper>
        {hint && !error && <FormInputHint>{hint}</FormInputHint>}
        {error && <FormInputError>{error}</FormInputError>}
      </FormInputLayout>
    );
  }
);
