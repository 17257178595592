import {
  Badge,
  BadgeAppearance,
  Text,
  ThemeDefinition,
  css,
  keyframes,
  styled,
} from '@yarmill/component-library';
import {
  Check,
  PriorityLow,
  Reload,
  Rename,
  Upload,
} from '@yarmill/icon-library';

export enum StateTagAppearance {
  concept = 'concept',
  ready = 'ready',
  read = 'read',
  error = 'error',
  readyToUpload = 'readyToUpload',
  uploading = 'uploading',
  processing = 'processing',
}

interface StateTagProps {
  readonly label?: string;
  readonly appearance: StateTagAppearance;
  readonly isActive?: boolean;
}

const rotateAnimationKeyframes = keyframes`
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(-360deg);
    }
`;
const rotateAnimation = css`
  animation-name: ${rotateAnimationKeyframes};
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.49, 0.07, 0.54, 0.91);
`;

const slideUpKeyframes = keyframes`
    0% {
      transform: translateY(0);
      transform-origin: bottom center;
    }
    20% {
      transform: translateY(10%) scaleY(80%);
    }
    48% {
      transform: translateY(-110%) scaleY(95%);
      opacity: 1;
      transition-property: transform, opacity;
    }
    49% {
        transform: translateY(-110%) translateX(110%) scaleY(95%);
    }
    50% {
      opacity: 0;
      transform: translateY(110%) translateX(110%) scaleY(95%);
      transition: none;
    }
    51% {
      transform: translateY(110%) translateX(0) scaleY(95%);
    }
    52% {
      opacity: 1;
      transform: translateY(110%) scaleY(95%);
      transition-property: transform, opacity;
    }
    100% {
      transform: translateY(0);
    }
`;
const slideUpAnimation = css`
  animation-name: ${slideUpKeyframes};
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.51, 0.01, 0.08, 0.99);
`;
function getIconForAppearance(appearance: StateTagAppearance) {
  switch (appearance) {
    case StateTagAppearance.concept:
      return <Rename />;
    case StateTagAppearance.error:
      return <PriorityLow />;
    case StateTagAppearance.readyToUpload:
      return <Check />;
    case StateTagAppearance.uploading:
      return <Upload />;
    case StateTagAppearance.processing:
      return <Reload />;
  }
}

function getBadgeAppearance(appearance: StateTagAppearance): BadgeAppearance {
  switch (appearance) {
    case StateTagAppearance.concept:
      return BadgeAppearance.recommendation;
    case StateTagAppearance.error:
      return BadgeAppearance.error;
    case StateTagAppearance.read:
      return BadgeAppearance.read;
    case StateTagAppearance.ready:
      return BadgeAppearance.ready;
    default:
      return BadgeAppearance.hint;
  }
}

function getBackgroundColor(theme: ThemeDefinition, isActive: boolean) {
  if (theme.dark) {
    return isActive
      ? theme.color.brand_yarmill_20
      : theme.color.background_background_04;
  } else {
    return isActive
      ? theme.color.brand_yarmill_20
      : theme.color.background_background_03;
  }
}

function getIconAnimation(
  appearance: StateTagAppearance
): ReturnType<typeof css> | undefined {
  switch (appearance) {
    case StateTagAppearance.processing:
      return rotateAnimation;
    case StateTagAppearance.uploading:
      return slideUpAnimation;
    default:
      return undefined;
  }
}

const Layout = styled.div<{ readonly $isActive: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transition: background 150ms ease, color 150ms ease;

  ${({ theme, $isActive }) => css`
    column-gap: ${theme.size.x075};
    border-radius: ${theme.borderRadius.x1};
    background-color: ${getBackgroundColor(theme, $isActive)};
    color: ${$isActive ? theme.color.brand_yarmill : theme.color.neutral_neutral};
    padding: ${theme.size.x025} ${theme.size.x075} ${theme.size.x025} ${theme.size.x05};

    ${
      !$isActive &&
      css`
        &:hover, .document-upload-list-item:hover & {
          background-color: ${theme.dark ? theme.color.background_background_05 : theme.color.background_background_03};
        }
      `
    };
  `}
`;

export function StateTag({
  label,
  appearance,
  isActive = false,
}: StateTagProps) {
  return (
    <Layout $isActive={isActive}>
      <Badge
        size={12}
        appearance={getBadgeAppearance(appearance)}
        icon={getIconForAppearance(appearance)}
        isActive={isActive}
        iconAnimation={getIconAnimation(appearance)}
      />
      <Text appearance="_11M">{label}</Text>
    </Layout>
  );
}
