import { useLogger } from '@yarmill/utils';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { MutableRefObject, useEffect, useRef } from 'react';
import { BreadcrumbsWrapper } from '../components';
import { DocumentForm } from '../form/document-form';
import { useDocumentsUploadStore } from '../hooks/use-documents-upload-store';
import { api } from '../metodej-api';
import { DocumentStore } from '../stores/document-store';
import { MetodejFormik } from '../types';
import { Breadcrumbs } from './breadcrumbs';

export const UploadFormDetail = observer(function UploadFormDetail() {
  const uploadStore = useDocumentsUploadStore();
  const documentStore = uploadStore.focusedItem;
  const logger = useLogger();
  const formikRef = useRef<MetodejFormik>();
  const abstract =
    documentStore instanceof DocumentStore ? documentStore.abstract : null;

  useEffect(() => {
    if (documentStore instanceof DocumentStore && !documentStore.isRead) {
      documentStore.isRead = true;
      try {
        api.markDocumentAsRead(documentStore.documentId);
      } catch (e) {
        logger.error(e);
      }
    }
  }, [documentStore, logger]);

  useEffect(() => {
    uploadStore.formikRef = formikRef as MutableRefObject<MetodejFormik>;
  }, [uploadStore]);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout> | null = null;

    async function getAbstract() {
      if (
        (!abstract || abstract.abstract === null) &&
        documentStore instanceof DocumentStore
      ) {
        await runInAction(async () => {
          await uploadStore.getAbstract(documentStore);
          if (!documentStore.abstract?.abstract) {
            timeout = setTimeout(() => getAbstract(), 3000);
          } else {
            uploadStore.formikRef?.current?.setFieldValue(
              'abstract',
              documentStore.abstract
            );
          }
        });
      }
    }

    getAbstract();

    return () => {
      if (timeout !== null) {
        clearTimeout(timeout);
      }
    };
  }, [uploadStore, abstract, documentStore]);

  return (
    <>
      <BreadcrumbsWrapper>
        <Breadcrumbs activeItem={3} />
      </BreadcrumbsWrapper>
      {documentStore instanceof DocumentStore && (
        <DocumentForm
          autoSave
          documentStore={documentStore}
          key={documentStore.documentId}
          formikRef={formikRef}
        />
      )}
    </>
  );
});
