import { BadgeAppearance, showToastMessage } from '@yarmill/component-library';
import { AsyncStatus } from '@yarmill/types';
import { observer } from 'mobx-react-lite';
import { MouseEvent, ReactElement } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import {
  DocumentUploadListItem,
  StateTag,
  StateTagAppearance,
} from '../components';
import { useDocumentsUploadStore } from '../hooks/use-documents-upload-store';
import { DocumentUploadStore } from '../stores/document-upload-store';

interface UploadingDocumentProps {
  readonly documentUploadStore: DocumentUploadStore;
}

function getTag(
  documentUploadStore: DocumentUploadStore,
  intl: IntlShape,
  isActive: boolean
): ReactElement | undefined {
  if (
    documentUploadStore.fileUpload.status === AsyncStatus.rejected ||
    documentUploadStore.createDocumentError
  ) {
    return (
      <StateTag
        label={intl.formatMessage({
          id: 'metodej.upload.drawer.tags.error',
        })}
        appearance={StateTagAppearance.error}
        isActive={isActive}
      />
    );
  } else if (documentUploadStore.fileUpload.status === AsyncStatus.pending) {
    return (
      <StateTag
        label={intl.formatMessage({
          id: 'metodej.upload.drawer.tags.uploading',
        })}
        appearance={StateTagAppearance.uploading}
        isActive={isActive}
      />
    );
  } else if (!documentUploadStore.document?.isAnnotated) {
    return (
      <StateTag
        label={intl.formatMessage({
          id: 'metodej.upload.drawer.tags.processing',
        })}
        appearance={StateTagAppearance.processing}
        isActive={isActive}
      />
    );
  }
  return undefined;
}

export const UploadingDocument = observer(function UploadingDocument({
  documentUploadStore,
}: UploadingDocumentProps) {
  const uploadStore = useDocumentsUploadStore();
  const intl = useIntl();

  function handleCancel(e: MouseEvent) {
    e.preventDefault();
    e.stopPropagation();
    uploadStore.cancelFileUpload(documentUploadStore);
    showToastMessage(
      intl.formatMessage({
        id: documentUploadStore.document
          ? 'metodej.form.upload.cancelDocumentProcessing.toast.success'
          : 'metodej.form.upload.cancelDocumentUpload.toast.success',
      }),
      BadgeAppearance.good
    );
  }

  function onClick() {
    uploadStore.setFocusedItem(documentUploadStore);
  }

  const label =
    documentUploadStore.document?.fileName ??
    documentUploadStore.document?.title ??
    documentUploadStore.fileUpload.name;

  const isActive = uploadStore.focusedItem === documentUploadStore;

  return (
    <DocumentUploadListItem
      label={label}
      handleCancelClick={handleCancel}
      onClick={onClick}
      cancelButtonAriaLabel={intl.formatMessage({
        id: 'metodej.upload.drawer.cancelButton.ariaLabel',
      })}
      isActive={isActive}
      tag={getTag(documentUploadStore, intl, isActive)}
      showProgressBar
    />
  );
});
