import { MiniButton, Text, css, styled } from '@yarmill/component-library';
import { Bin, Check, Document, Multiply, Reload } from '@yarmill/icon-library';
import { MouseEventHandler, ReactElement } from 'react';
import { ProgressBar } from './progress-bar';

interface DocumentFormUploadListItemProps {
  readonly label: string;
  readonly showProgressBar?: boolean;
  readonly handleCancelClick?: MouseEventHandler;
  readonly handleRetryClick?: MouseEventHandler;
  readonly success?: boolean;
  readonly error?: boolean;
  readonly successText?: string;
  readonly errorText?: string;
}

const Layout = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  ${({ theme }) => css`
    padding: ${theme.size.x2};
    column-gap: ${theme.size.x1};
    border-radius: ${theme.borderRadius.x15};
    background-color: ${theme.color.background_background_02};
    color: ${theme.color.neutral_neutral_dark};
  `};
`;

const IconWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: ${theme.size.x1};
    color: ${theme.color.neutral_neutral};
    width: ${theme.size.x25};
    height: ${theme.size.x25};

    svg {
      width: ${theme.size.x25};
      height: ${theme.size.x25};
    }
  `};
`;

const ProgressWrapper = styled.div`
  grid-column: 1 / -1;
  grid-row: 2 / 3;

  ${({ theme }) => css`
    margin-top: ${theme.size.x2};
  `};
`;

const StatusWrapper = styled.div`
  grid-column: 2 / 3;
  grid-row: 2 / 3;
  display: flex;
  align-items: center;

  ${({ theme }) => css`
    color: ${theme.color.neutral_neutral_64};
    column-gap: ${theme.size.x05};
  `};
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  grid-column:  3 / 4;
  align-self: flex-start;

  ${({ theme }) => css`
    column-gap: ${theme.size.x1};
  `};

  &:has(~ ${StatusWrapper}), &:has(~ ${ProgressWrapper}) {
    grid-row: 1 / 3;
  }
`;

const StatusIconWrapper = styled.div<{ readonly success?: boolean }>`
  ${({ theme, success }) => css`
    width: ${theme.size.x25};
    height: ${theme.size.x25};
    color: ${success ? theme.color.other_green : theme.color.other_red};

    svg {
      width: ${theme.size.x25};
      height: ${theme.size.x25};
    }
  `};
`;

export function DocumentFormUploadListItem({
  label,
  handleRetryClick,
  handleCancelClick,
  success,
  error,
  successText,
  errorText,
  showProgressBar,
}: DocumentFormUploadListItemProps): ReactElement {
  return (
    <Layout>
      <IconWrapper>
        <Document aria-hidden />
      </IconWrapper>
      <Text appearance="_12M" ellipsis whiteSpace="noWrap">
        {label}
      </Text>
      <ButtonsWrapper>
        {handleRetryClick && (
          <MiniButton onClick={handleRetryClick} type="button">
            <Reload />
          </MiniButton>
        )}
        {handleCancelClick && (
          <MiniButton onClick={handleCancelClick} type="button">
            <Bin />
          </MiniButton>
        )}
      </ButtonsWrapper>
      {success && (
        <StatusWrapper>
          <StatusIconWrapper success>
            <Check />
          </StatusIconWrapper>
          <Text appearance="_12M">{successText}</Text>
        </StatusWrapper>
      )}
      {error && (
        <StatusWrapper>
          <StatusIconWrapper>
            <Multiply />
          </StatusIconWrapper>
          <Text appearance="_12M">{errorText}</Text>
        </StatusWrapper>
      )}
      {showProgressBar && (
        <ProgressWrapper>
          <ProgressBar />
        </ProgressWrapper>
      )}
    </Layout>
  );
}
