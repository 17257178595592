import {
  Button,
  ButtonAppearance,
  DropdownOption,
  DropdownProvider,
} from '@yarmill/component-library';
import { Plus } from '@yarmill/icon-library';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { DocumentType, MetodejDocument } from '../types';
import { formFieldLayoutDefinitions } from './form-field-layout-definitions';

interface ShowAdditionalFieldButtonProps {
  readonly documentType: DocumentType | null;
  readonly visibleFields: (keyof MetodejDocument)[];
  readonly showAdditionalField: (name: keyof MetodejDocument) => void;
}
export const ShowAdditionalFieldButton = observer(
  function ShowAdditionalFieldButton({
    documentType,
    visibleFields,
    showAdditionalField,
  }: ShowAdditionalFieldButtonProps) {
    const intl = useIntl();
    const options = useMemo<DropdownOption<keyof MetodejDocument>[]>(
      () =>
        Object.keys(formFieldLayoutDefinitions)
          .filter(
            field => !visibleFields.includes(field as keyof MetodejDocument)
          )
          .map(field => ({
            label: intl.formatMessage(
              {
                id: `metodej.document.form.field.${field}.label`,
              },
              {
                documentType,
              }
            ),
            value: field as keyof MetodejDocument,
            showPlusIcon: true,
          })),
      [intl, visibleFields, documentType]
    );

    return (
      <div>
        <DropdownProvider
          options={options}
          handleSelect={showAdditionalField as any}
          searchable
          searchInputPlaceholder={intl.formatMessage({
            id: 'metodej.document.form.showAdditionalFieldButton.searchInputPlaceholder',
          })}
        >
          <Button
            as="div"
            icon={<Plus />}
            appearance={ButtonAppearance.Secondary}
          >
            {intl.formatMessage({
              id: 'metodej.document.form.showAdditionalFieldButton.label',
            })}
          </Button>
        </DropdownProvider>
      </div>
    );
  }
);
