import { css, styled } from '@yarmill/component-library';
import { times } from '@yarmill/utils';
import { LibraryLoadingSkeletonRow } from './library-loading-skeleton-row';

const Layout = styled.div`
  width: 100%;
  mask-image: linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,0));
  overflow: hidden;

  ${({ theme }) => css`
    & > * {
      margin: 0 ${theme.size.x05} ${theme.size.x1};
    }
    & > *:nth-child(1) {
      margin-top: ${theme.size.x1};
    }
  `};
`;

export function LibraryLoadingSkeleton() {
  return (
    <Layout>
      {times(6).map((_, idx) => (
        <LibraryLoadingSkeletonRow key={idx} />
      ))}
    </Layout>
  );
}
