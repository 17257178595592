import { ServerError } from '@yarmill/types';
import { axios } from '@yarmill/utils';
import { AxiosPromise } from 'axios';
import { action, computed, makeObservable, observable } from 'mobx';
import {
  CreateDocumentRequestPayload,
  MetodejDocument,
  PublishDocumentRequestPayload,
  UpdateDocumentRequestPayload,
} from './types';

class MetodejApi {
  @observable
  private _baseUrl: string = '';

  constructor() {
    makeObservable(this);
  }

  async createDocument(
    payload: CreateDocumentRequestPayload
  ): AxiosPromise<MetodejDocument> {
    return axios.post(`${this._baseUrl}/api/document/upload`, payload);
  }

  async getUnpublishedDocuments(): AxiosPromise<MetodejDocument[]> {
    return this.getDocuments(false);
  }

  async getPublishedDocuments(): AxiosPromise<MetodejDocument[]> {
    return this.getDocuments(true);
  }

  async getDocument(documentId: number): AxiosPromise<MetodejDocument> {
    return axios.get(`${this._baseUrl}/api/document`, {
      params: { documentId },
    });
  }

  private getDocuments(isPublished: boolean): AxiosPromise<MetodejDocument[]> {
    return axios.get(`${this._baseUrl}/api/document/list`, {
      params: { isPublished },
    });
  }

  async publishDocument(
    payload: PublishDocumentRequestPayload
  ): AxiosPromise<boolean> {
    return axios.post(`${this._baseUrl}/api/document/publish`, payload);
  }

  async unpublishDocument(
    payload: PublishDocumentRequestPayload
  ): AxiosPromise<boolean> {
    return axios.post(`${this._baseUrl}/api/document/unpublish`, payload);
  }

  async markDocumentAsRead(documentId: number): AxiosPromise<boolean> {
    return axios.post(`${this._baseUrl}/api/document/markAsRead`, undefined, {
      params: { documentId },
    });
  }

  async deleteDocument(documentId: number): AxiosPromise<boolean> {
    return axios.delete(`${this._baseUrl}/api/document/delete`, {
      params: {
        documentId,
      },
    });
  }

  async updateDocument(
    params: UpdateDocumentRequestPayload
  ): AxiosPromise<boolean | ServerError> {
    return axios.put(`${this._baseUrl}/api/document/update`, params);
  }

  @action
  setBaseUrl(url: string) {
    this._baseUrl = url;
  }

  @computed
  get hasBaseUrl() {
    return Boolean(this._baseUrl);
  }
}

export const api = new MetodejApi();
