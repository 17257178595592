import { Group } from '@visx/group';
import { useMemo } from 'react';
import styled from 'styled-components';
import {
  AxisConfig,
  AxisValue,
  DataItem,
  Domain,
  GetTooltipData,
  GridLinesConfig,
  XAxisConfig,
  YAxisConfig,
} from '../reporting/types';
import { Axes } from './axes';
import { BackgroundImage } from './background-image';
import { ChartElements } from './chart-elements';
import { DataLabels } from './data-labels';
import { GridLines } from './grid-lines';
import { useCalculateLeftPadding } from './hooks/use-calculate-left-padding';
import { useCalculateRightPadding } from './hooks/use-calculate-right-padding';
import { useCalculateTopPadding } from './hooks/use-calculate-top-padding';
import { useMarkerPadding } from './hooks/use-marker-padding';
import { useScale } from './hooks/use-scale';
import { TooltipContext, useTooltip } from './hooks/use-tooltip';
import { useXAxisHeight } from './hooks/use-x-axis-height';
import { RangeMask } from './range-mask';
import { Tooltip } from './tooltip';
import { ChartConfig } from './types';
import { XYChartContext } from './xy-chart-context';

export * from './types';
export * from './tooltip/shared-tooltip-context';

interface XyChartProps {
  readonly axisConfigs: AxisConfig<AxisValue, AxisValue>[];
  readonly axisYTicks: AxisValue[] | undefined;
  readonly axisXTicks: AxisValue[] | undefined;
  readonly code: string;
  readonly configs: ChartConfig[];
  readonly data: DataItem[];
  readonly domainX: Domain;
  readonly domainY: Domain;
  readonly getTooltipData: GetTooltipData;
  readonly gridLinesColumnsTicks?: AxisValue[];
  readonly gridLinesConfig?: GridLinesConfig;
  readonly gridLinesRowsTicks?: AxisValue[];
  readonly hideTooltip?: boolean;
  readonly height: number;
  readonly width: number;
  readonly backgroundImage?: string;
  readonly syncTooltip?: boolean;
}

const ChartWrapper = styled.div`
  width: 100%;
  height: 100%;

  .axis-line {
    stroke: #a9a9a9;
  }
`;

export function XyChart(props: XyChartProps) {
  const {
    axisConfigs,
    axisXTicks,
    axisYTicks,
    code,
    configs,
    data,
    domainX,
    domainY,
    getTooltipData,
    gridLinesColumnsTicks,
    gridLinesConfig,
    gridLinesRowsTicks,
    height,
    width,
    backgroundImage,
    hideTooltip,
    syncTooltip,
  } = props;

  const xAxisConfig = axisConfigs.find(
    (axis): axis is XAxisConfig<AxisValue> => axis.axis === 'x'
  );
  const yAxisConfig = axisConfigs.find(
    (axis): axis is YAxisConfig<AxisValue> => axis.axis === 'y'
  );

  const yScale = useScale(domainY, yAxisConfig?.type ?? 'continuous');
  const xScale = useScale(domainX, xAxisConfig?.type ?? 'continuous');

  const isHorizontal = 'bandwidth' in yScale;
  const mainAxisKey = isHorizontal ? yAxisConfig?.key : xAxisConfig?.key;

  const [
    containerRef,
    tooltipContextValue,
    handleSvgMouseMove,
    handleSvgMouseLeave,
  ] = useTooltip(xScale, yScale, getTooltipData, mainAxisKey, syncTooltip);

  useMemo(() => {
    xScale.range([0, width]);
  }, [xScale, width]);

  useMemo(() => {
    yScale.range([height, 0]);
  }, [yScale, height]);

  const xAxisHeight = useXAxisHeight(xScale, xAxisConfig);
  const markerPadding = useMarkerPadding(configs, data);

  const paddingBottom = xAxisHeight + markerPadding;
  const paddingLeft = useCalculateLeftPadding(
    yScale,
    xScale,
    yAxisConfig,
    xAxisConfig,
    markerPadding,
    configs,
    data
  );
  const paddingRight = useCalculateRightPadding(
    xScale,
    yScale,
    xAxisConfig,
    markerPadding,
    configs,
    data
  );
  const xMax = width - paddingRight;
  const yMax = height - paddingBottom;

  useMemo(() => {
    xScale.range([paddingLeft, xMax]);
  }, [xScale, paddingLeft, xMax]);

  const paddingTop = useCalculateTopPadding(configs, data, xScale, yAxisConfig);

  useMemo(() => {
    yScale.range([yMax, paddingTop]);
  }, [yScale, yMax, paddingTop]);

  const xyChartContextValue = useMemo(
    () => ({
      xScale,
      yScale,
      chartRect: {
        top: paddingTop,
        left: paddingLeft,
        right: xMax,
        bottom: yMax,
      },
    }),
    [paddingLeft, paddingTop, xMax, xScale, yMax, yScale]
  );

  return (
    <XYChartContext.Provider value={xyChartContextValue}>
      <TooltipContext.Provider value={tooltipContextValue}>
        <ChartWrapper>
          <svg
            width={width}
            height={height}
            ref={containerRef}
            onMouseMove={handleSvgMouseMove}
            onMouseLeave={handleSvgMouseLeave}
          >
            <RangeMask code={code} markerPadding={markerPadding} />
            <BackgroundImage image={backgroundImage} />
            <GridLines
              xAxisConfig={xAxisConfig}
              yAxisConfig={yAxisConfig}
              config={gridLinesConfig}
              columnTicks={gridLinesColumnsTicks}
              rowTicks={gridLinesRowsTicks}
            />
            <Axes
              xAxisConfig={xAxisConfig}
              yAxisConfig={yAxisConfig}
              xAxisTicks={axisXTicks}
              yAxisTicks={axisYTicks}
            />
            <Tooltip
              hide={hideTooltip}
              syncTooltip={syncTooltip}
              mainAxisKey={mainAxisKey}
            />
            <Group mask={`url(#${code})`}>
              <ChartElements configs={configs} data={data} />
            </Group>
            <DataLabels configs={configs} data={data} />
          </svg>
        </ChartWrapper>
      </TooltipContext.Provider>
    </XYChartContext.Provider>
  );
}
