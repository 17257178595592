import { ThemeProvider } from '@yarmill/components';
import {
  AppContainer,
  PageContentWrapper,
  PrimarySidebar,
} from '@yarmill/components-2';
import { useHelmetTitle, useRootStore, useSidebarStore } from '@yarmill/utils';
import { observer } from 'mobx-react-lite';
import { memo } from 'react';
import Helmet from 'react-helmet';
import { useIntl } from 'react-intl';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { EmptyPage } from './empty-page';
import { useEnsureUrlParameters } from './hooks/use-ensure-url-parameters';
import { ObjectiveDetail } from './objective-detail';
import { ObjectivesGroupOverview } from './objectives-group-overview';
import { ObjectivesList } from './objectives-list';
import { OkrsStoreContextProvider, useOkrsStore } from './okrs-store-context';

function InternalOkrsRouteRoot(): JSX.Element | null {
  useEnsureUrlParameters();
  const { path } = useRouteMatch();
  const rootStore = useRootStore();
  const sidebarStore = useSidebarStore();
  const okrsStore = useOkrsStore();
  const showObjectivesList = okrsStore.athleteId;
  const intl = useIntl();
  const helmetTitle = useHelmetTitle([
    okrsStore.athlete?.displayName ?? okrsStore.group?.name,
    intl.formatMessage({ id: 'header.navigation.okrs' }),
  ]);

  return rootStore.isReady ? (
    <AppContainer>
      <Helmet>
        <title>{helmetTitle}</title>
        <meta name="theme-color" content="#edf0f5" />
      </Helmet>
      <PrimarySidebar />
      <PageContentWrapper isSidebarVisible={sidebarStore.isVisible}>
        {showObjectivesList && <ObjectivesList />}
        <Switch>
          <Route exact path={path}>
            {okrsStore.athleteId ? <EmptyPage /> : <ObjectivesGroupOverview />}
          </Route>
          <Route path={`${path}/:id`}>
            <ObjectiveDetail />
          </Route>
        </Switch>
      </PageContentWrapper>
    </AppContainer>
  ) : null;
}

const ObserverOkrsRouteRoot = observer(InternalOkrsRouteRoot);

export const OkrsModule = memo(() => (
  <OkrsStoreContextProvider>
    <ThemeProvider theme="main">
      <ObserverOkrsRouteRoot />
    </ThemeProvider>
  </OkrsStoreContextProvider>
));
