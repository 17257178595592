import { AsyncStatus, FileUploadStore } from '@yarmill/types';
import { useConfig, useFileUploadService } from '@yarmill/utils';
import { observer } from 'mobx-react-lite';
import { Dispatch, SetStateAction } from 'react';
import { useIntl } from 'react-intl';
import { DocumentFormUploadListItem } from '../components';
import { isValidFileExtension } from '../utils/is-valid-file-extension';
import { uploadAgreementFile } from '../utils/upload-agreement-file';

interface AgreementFileProps {
  readonly agreementFile: FileUploadStore;
  readonly setAgreementFile: Dispatch<SetStateAction<FileUploadStore | null>>;
}
export const AgreementFile = observer(function AgreementFile({
  agreementFile,
  setAgreementFile,
}: AgreementFileProps) {
  const intl = useIntl();
  const fileUploadService = useFileUploadService();
  const applicationId = useConfig('metodejApplicationId');

  function removeAgreementFile() {
    agreementFile?.cancelUpload();
    setAgreementFile(null);
  }

  async function retryFileUpload() {
    if (agreementFile) {
      await uploadAgreementFile(
        agreementFile.originalFile,
        fileUploadService,
        setAgreementFile,
        applicationId
      );
    }
  }

  return (
    <DocumentFormUploadListItem
      label={agreementFile.name}
      handleCancelClick={removeAgreementFile}
      error={
        !isValidFileExtension(agreementFile.name) ||
        agreementFile.status === AsyncStatus.rejected
      }
      errorText={
        agreementFile.status === AsyncStatus.rejected
          ? intl.formatMessage({
              id: 'metodej.upload.form.agreement.uploadList.file.uploadError',
            })
          : intl.formatMessage({
              id: 'metodej.upload.form.agreement.uploadList.file.invalidType',
            })
      }
      handleRetryClick={
        agreementFile?.status === AsyncStatus.rejected
          ? retryFileUpload
          : undefined
      }
      showProgressBar={agreementFile.status === AsyncStatus.pending}
    />
  );
});
