import {
  AsyncStatus,
  File as FileUploadFile,
  FileUploadService,
} from '@yarmill/types';
import { GlobalLogger } from '@yarmill/utils';
import { AxiosPromise } from 'axios';
import {
  IObservableArray,
  ObservableMap,
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
  when,
} from 'mobx';
import { MutableRefObject } from 'react';
import { api } from '../metodej-api';
import { AgreementType, MetodejDocument, MetodejFormik } from '../types';
import { DocumentStore } from './document-store';
import { DocumentUploadStore } from './document-upload-store';

export class DocumentsUploadStore {
  private _pollTimeout = 5000;
  private _enablePolling = true;

  @observable
  private _formStep: number = 1;

  @observable
  private readonly _filesToUpload: IObservableArray<File> = observable.array();

  @observable
  private _agreementType: AgreementType = 'bearer';

  @observable
  private _agreementFile: FileUploadFile | null = null;

  @observable
  private _agreementTimestamp: string = new Date().toISOString();

  @observable
  private readonly _fileUploads: IObservableArray<DocumentUploadStore> =
    observable.array();

  @observable
  private readonly _canceledFileUploads: IObservableArray<DocumentUploadStore> =
    observable.array();

  @observable
  private readonly _documentsToCheck: ObservableMap<number, DocumentStore> =
    observable.map();

  @observable
  private readonly _publishedDocuments: ObservableMap<number, DocumentStore> =
    observable.map();

  @observable
  private _focusedItem: DocumentUploadStore | DocumentStore | null = null;

  private _loadUnpublishedDocumentsRequest: AxiosPromise<
    MetodejDocument[]
  > | null = null;

  @observable
  private _initialDataLoaded: boolean = false;

  public formikRef: MutableRefObject<MetodejFormik> | null = null;

  constructor() {
    makeObservable(this);
    when(
      () => api.hasBaseUrl,
      () => {
        this.pollUnpublishedDocuments();
      }
    );
  }

  get currentFormStep(): number {
    return this._formStep;
  }

  @action
  goToNextStep(): void {
    if (this._formStep < 3) {
      this._formStep += 1;
    }
  }

  @action
  goToPreviousStep(): void {
    if (this._formStep !== 1) {
      this._formStep -= 1;
    }
  }

  @action
  resetFormStep(): void {
    this._formStep = 1;
  }

  get filesToUpload() {
    return this._filesToUpload;
  }

  get focusedItem() {
    return this._focusedItem;
  }

  @action
  setFocusedItem(document: DocumentUploadStore | DocumentStore | null): void {
    this._focusedItem = document;
  }

  @action
  setFilesToUpload(file: File[]): void {
    this._filesToUpload.replace(file);
  }

  @action
  setAgreementType(agreementType: AgreementType): void {
    this._agreementType = agreementType;
    this._agreementTimestamp = new Date().toISOString();
  }

  @action
  setAgreementFile(agreementFile: FileUploadFile): void {
    this._agreementFile = agreementFile;
    this._agreementTimestamp = new Date().toISOString();
  }

  get documentsToCheck() {
    return this._documentsToCheck;
  }

  get publishedDocuments() {
    return this._publishedDocuments;
  }

  @computed
  get uploadingDocuments(): DocumentUploadStore[] {
    return this._fileUploads.filter(
      store =>
        store.fileUpload.status !== AsyncStatus.resolved ||
        !store.document?.isAnnotated
    );
  }

  @action
  cancelFileUpload(document: DocumentUploadStore) {
    this.setFocusedItem(null);
    this.resetFormStep();

    if (!document.fileUpload.uploadedFile) {
      document.cancelUpload();
    } else {
      when(
        () => document.document !== null,
        () => {
          const doc = document.document;
          if (doc) {
            this.removeDocument(doc);
          }
        }
      );
    }

    this._canceledFileUploads.push(document);
    this._fileUploads.remove(document);
  }

  @action
  async uploadFiles(
    fileUploadService: FileUploadService,
    applicationId: string
  ): Promise<void> {
    const agreementType = this._agreementType;
    const agreementFile = this._agreementFile;
    const agreementTimestamp = this._agreementTimestamp;

    this.filesToUpload.forEach(file => {
      const fileUploadStore = fileUploadService.uploadFile(file, applicationId);
      const documentUploadStore = new DocumentUploadStore(
        fileUploadStore,
        agreementType,
        agreementFile,
        agreementTimestamp
      );
      fileUploadStore.uploadFile();
      this._fileUploads.push(documentUploadStore);
    });
    this.filesToUpload.clear();

    if (!this._enablePolling) {
      this._enablePolling = true;
      this.pollUnpublishedDocuments();
    }
  }

  private async pollUnpublishedDocuments() {
    if (!this._enablePolling) {
      return;
    }

    const unpublishedDocuments = await this.loadUnpublishedDocuments();
    const annotatedDocuments: MetodejDocument[] = [];
    const nonAnnotatedDocuments: MetodejDocument[] = [];

    unpublishedDocuments.forEach(document => {
      const isCanceled = this._canceledFileUploads.find(
        doc => doc.document?.documentId === document.documentId
      );

      if (isCanceled) {
        return;
      }
      if (document.isAnnotated) {
        annotatedDocuments.push(document);
      } else {
        nonAnnotatedDocuments.push(document);
      }
    });

    runInAction(() => {
      const documentsToRemove = this._fileUploads.filter(store => {
        return annotatedDocuments.find(
          doc => doc.documentId === store.document?.documentId
        );
      });

      let focusedDocumentIdToReplace: number | null = null;
      if (
        this.focusedItem instanceof DocumentUploadStore &&
        documentsToRemove.includes(this.focusedItem)
      ) {
        focusedDocumentIdToReplace =
          this.focusedItem.document?.documentId ?? null;
        this.setFocusedItem(null);
      }

      this._fileUploads.replace(
        this._fileUploads.filter(store => {
          const existingAnnotatedDocument = annotatedDocuments.find(
            doc => doc.documentId === store.document?.documentId
          );

          return !existingAnnotatedDocument;
        })
      );

      annotatedDocuments.forEach(doc => {
        if (this._documentsToCheck.has(doc.documentId)) {
          return;
        } else {
          this._documentsToCheck.set(doc.documentId, new DocumentStore(doc));
        }
      });

      nonAnnotatedDocuments.forEach(doc => {
        if (
          !this._fileUploads.find(
            upload => upload.document?.documentId === doc.documentId
          )
        ) {
          const store = new DocumentUploadStore(
            new DocumentStore(doc),
            'bearer', // is not used
            null,
            new Date().toISOString()
          );

          this._fileUploads.push(store);
        }
      });

      if (focusedDocumentIdToReplace !== null) {
        const documentToCheck = this.documentsToCheck.get(
          focusedDocumentIdToReplace
        );
        if (documentToCheck) {
          this.setFocusedItem(documentToCheck);
        }
      }

      if (!this._initialDataLoaded) {
        this._initialDataLoaded = true;
      }

      if (this._fileUploads.length === 0) {
        this._enablePolling = false;
      } else {
        setTimeout(() => this.pollUnpublishedDocuments(), this._pollTimeout);
      }
    });
  }

  private async loadUnpublishedDocuments(): Promise<MetodejDocument[]> {
    try {
      if (this._loadUnpublishedDocumentsRequest) {
        return [];
      }
      const request = api.getUnpublishedDocuments();
      this._loadUnpublishedDocumentsRequest = request;
      const response = await request;
      if (response.status === 200) {
        return response.data;
      }
    } catch (e) {
      GlobalLogger.error(e);
    } finally {
      this._loadUnpublishedDocumentsRequest = null;
    }

    return [];
  }

  async publishDocument(document: DocumentStore) {
    await api.publishDocument({
      documentId: document.documentId,
      timestamp: new Date().toISOString(),
    });

    runInAction(() => {
      this._publishedDocuments.set(document.documentId, document);
      this._documentsToCheck.delete(document.documentId);
    });
  }

  async unpublishDocument(document: DocumentStore) {
    await api.unpublishDocument({
      documentId: document.documentId,
      timestamp: new Date().toISOString(),
    });

    runInAction(() => {
      this._publishedDocuments.delete(document.documentId);
      this._documentsToCheck.set(document.documentId, document);
    });
  }

  @action
  async removeDocument(document: DocumentStore) {
    await api.deleteDocument(document.documentId);
    this.resetFormStep();
    this._documentsToCheck.delete(document.documentId);
    this.setFocusedItem(null);
  }

  get initialDataLoaded() {
    return this._initialDataLoaded;
  }

  async getAbstract(documentStore: DocumentStore) {
    const response = await api.getDocument(documentStore.documentId);

    if (response.status === 200) {
      const document = response.data;
      if (document.abstract && document.abstract.abstract) {
        documentStore.abstract = document.abstract;
      }
    }
  }
}
