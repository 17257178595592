import { useFormikContext } from 'formik';
import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import { DocumentFormSection, DocumentFormSectionRow } from '../components';
import { DocumentFormEmptyCiteSection } from '../components/document-form-empty-cite-section';
import { MetodejDocument } from '../types';
import { AbstractField } from './abstract-field';
import { CoverField } from './cover-fields';
import { FormCiteFields } from './form-cite-fields';
import { SelectField } from './select-field';
import { TagField } from './tag-field';
import { TextField } from './text-field';

export const FormLayout = observer(function FormLayout() {
  const intl = useIntl();
  const formik = useFormikContext<MetodejDocument>();
  const { documentType, documentFormat } = formik.values;
  const showCiteFields = documentType && documentFormat;

  return (
    <>
      <DocumentFormSection>
        <CoverField />
        <DocumentFormSectionRow>
          <TextField name="title" required />
        </DocumentFormSectionRow>
        <DocumentFormSectionRow>
          <TextField name="subTitle" />
        </DocumentFormSectionRow>
        <DocumentFormSectionRow columns={2}>
          <SelectField name="documentFormat" required />
        </DocumentFormSectionRow>
        <DocumentFormSectionRow columns={2}>
          <SelectField name="documentType" required />
        </DocumentFormSectionRow>
      </DocumentFormSection>
      <DocumentFormSection
        headline={intl.formatMessage({
          id: 'metodej.document.form.section.cite.headline',
        })}
      >
        {!showCiteFields && (
          <DocumentFormEmptyCiteSection
            text={intl.formatMessage({
              id: 'metodej.document.form.section.cite.selectDocumentTypeText',
            })}
          />
        )}
        {showCiteFields && <FormCiteFields />}
      </DocumentFormSection>
      <AbstractField />
      <DocumentFormSection
        headline={intl.formatMessage({
          id: 'metodej.document.form.section.categories.headline',
        })}
      >
        <DocumentFormSectionRow>
          <TagField name="sports" />
        </DocumentFormSectionRow>
        <DocumentFormSectionRow>
          <TagField name="subjects" />
        </DocumentFormSectionRow>
        <DocumentFormSectionRow>
          <TagField name="ageCategories" />
        </DocumentFormSectionRow>
      </DocumentFormSection>
      <DocumentFormSection
        headline={intl.formatMessage({
          id: 'metodej.document.form.section.tags.headline',
        })}
      >
        <DocumentFormSectionRow>
          <TagField name="tags" noLabel />
        </DocumentFormSectionRow>
      </DocumentFormSection>
    </>
  );
});
