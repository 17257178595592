import {
  BadgeAppearance,
  Button,
  ButtonAppearance,
  Popover,
  Text,
  showToastMessage,
} from '@yarmill/component-library';
import { useHistory, useLogger } from '@yarmill/utils';
import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import { useRouteMatch } from 'react-router-dom';
import { useDocumentLibraryStore } from '../hooks/use-document-library-store';
import { DocumentStore } from '../stores/document-store';
import { getMetodejUrl } from '../utils/get-metodej-url';

interface RemovePublishedDocumentPopoverProps {
  readonly closePopover: () => void;
}
export const RemovePublishedDocumentPopover = observer(
  function RemovePublishedDocumentPopover({
    closePopover,
  }: RemovePublishedDocumentPopoverProps) {
    const intl = useIntl();
    const logger = useLogger();
    const libraryStore = useDocumentLibraryStore();
    const history = useHistory();
    const {
      params: { id },
    } = useRouteMatch<{ readonly id: string }>();
    const document = id ? libraryStore.documentsById.get(Number(id)) : null;

    if (!(document instanceof DocumentStore)) {
      return null;
    }

    async function removeDocument() {
      if (document instanceof DocumentStore) {
        try {
          closePopover();
          await libraryStore.removeDocument(document);
          showToastMessage(
            intl.formatMessage({
              id: 'metodej.form.upload.removeDocumentAlert.toast.success',
            }),
            BadgeAppearance.good
          );
          history.push(getMetodejUrl('library'));
        } catch (error) {
          logger.error(error);
          showToastMessage(
            intl.formatMessage({
              id: 'metodej.form.upload.removeDocumentAlert.toast.error',
            }),
            BadgeAppearance.error
          );
        }
      }
    }

    return (
      <Popover
        label={intl.formatMessage({
          id: 'metodej.form.upload.removeDocumentAlert.headline',
        })}
        buttons={
          <>
            <Button
              appearance={ButtonAppearance.Tertiary}
              onClick={closePopover}
            >
              {intl.formatMessage({
                id: 'metodej.form.upload.removeDocumentAlert.cancelButtonLabel',
              })}
            </Button>
            <Button
              appearance={ButtonAppearance.Error}
              onClick={removeDocument}
            >
              {intl.formatMessage({
                id: 'metodej.form.upload.removeDocumentAlert.removeButtonLabel',
              })}
            </Button>
          </>
        }
      >
        <Text appearance="_12M" as="p" align="center">
          {intl.formatMessage(
            {
              id: 'metodej.form.upload.removeDocumentAlert.text',
            },
            {
              filesName: document.title,
            }
          )}
        </Text>
      </Popover>
    );
  }
);
