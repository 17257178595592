import { PropsWithChildren, ReactElement } from 'react';
import { DRAWER_WIDTH } from './page-layout';
import { css, styled } from './theme-provider';

const Layout = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: stretch;
  min-width: ${DRAWER_WIDTH}px;

  ${({ theme }) => css`
    border-radius: ${theme.borderRadius.x2};
    background-color: ${theme.color.background_background_02};
  `};
`;

const HeaderWrapper = styled.div`
  flex: 0;
`;

const ContentWrapper = styled.div`
  flex: 1;
  overflow: auto;
  ${({ theme }) => css`
    padding-bottom: ${theme.size.x1};
  `};
`;

type DrawerProps = PropsWithChildren<{ readonly header: ReactElement }>;
export function Drawer({ header, children }: DrawerProps) {
  return (
    <Layout>
      <HeaderWrapper>{header}</HeaderWrapper>
      <ContentWrapper>{children}</ContentWrapper>
    </Layout>
  );
}
